@import "../../../../_var.scss";

.detail-tab {
  &_itemOrderCard {
    background-color: $whiteText;
    min-height: 60px;
    border-radius: 9px;
    border: 1px solid $greyBorder;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 14px;
    opacity: 1;
    color: $blackfade;
    padding-left: 17px;
    padding-right: 35px;
    font-family: $baseFont;

    .ui.accordion .title:not(.ui) {
      min-height: 60px;
      margin-bottom: 0;
      padding: 0;
      font-family: $baseFont;
    }
  }

  &_status {
    opacity: 1;
    font-weight: 500;
    font-size: 12px;
    margin-top: -5px;
    font-family: $baseFont;
    width: 85px;
    height: 32px;
  }
}

.detail-tab_shippingOrderId {
  display: flex;
  min-height: 60px;
  align-items: center;
}

.detail-tab_shippingOrderStatus {
  display: flex;
  width: 85px;
  height: 32px;
  border: 1px solid #EDEDED;
  border-radius: 8px;
  background-color: #E7E7E7;
  color: #23262C;
  align-items: center;
  justify-content: center;
}

.detail-tab_shippingOrderStatusDelivered {
  background-color: #E7E7E7;
}

.detail-tab_shippingOrderStatusShipped {
  background-color: #F97245;
  color: #fff;
}

.detail-tab_shippingOrderStatusPrepared {
  background-color: #EFE736;
}

.detail-tab_shippingOrderNftsWrapper {
  margin-bottom: 16px;
  padding: 10px 0;
  font-size: 12px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.6);
}

.detail-tab_shippingOrderNftsHeader {
  margin-bottom: 6px;
  padding: 0 15px 6px;
  border-bottom: 1px solid #000;
  font-weight: 700;
}

.detail-tab_shippingOrderNftsBody {
  >div {
    padding: 3px 15px;

    &:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }


  }
}

.detail-tab_shippingOrderFlexJustifyEnd {
  display: flex;
  justify-content: flex-end;
}

.detail-tab_shippingOrderNftLink {
  .ant-btn-primary {
    background-color: $defult-500;
    border-color: $defult-500;
    letter-spacing: 0.5px;
  }
}