@import "../../../_var.scss";

.wareHouse {
  position: relative;

  .common-sub-heading {
    margin-bottom: 30px;
  }

  .StatusWarrap {
    position: absolute;
    top: 0;
    right: 70px;

    .listing {
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 5px;
        line-height: 20px;
      }
    }
  }
}

.kebebMenu {
  min-width: 1px !important;
}

.SpacingList {
  min-width: 100px !important;
}

.btnApproved {
  background: $green 0% 0% no-repeat padding-box;
  border: 1px solid $boxBg;
  border-radius: 8px;
  height: 35px;
  min-width: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $whiteText;

  @media (max-width: 767px) {
    width: 200px;
  }
}

.btnDraft {
  background: $borderColor2 0% 0% no-repeat padding-box;
  border: 1px solid $boxBg;
  border-radius: 8px;
  height: 35px;
  min-width: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;

  @media (max-width: 767px) {
    width: 200px;
  }
}

.btnLive {
  background: $diclined 0% 0% no-repeat padding-box;
  border: 1px solid $boxBg;
  border-radius: 8px;
  height: 35px;
  min-width: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $whiteText;

  @media (max-width: 767px) {
    width: 200px;
  }
}

.wrapSpacing {
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu.transition.visible {
  display: table !important;
}
