@import "../../../../_var.scss";

.tabOver {
  margin-top: 8px;
  margin-bottom: 120px;
  &__ownerDetails {
    color: $darkText2;
    font-weight: bold;
    font-family: $baseFont2;
    font-size: 20px;
    opacity: 1;
    margin-bottom: 24px !important;
  }
  .ant-form.wcForm .ant-form-item-label label {
    color: $blackfade;
    line-height: 15px;
    font-weight: bold;
    font-size: 12px;
    opacity: 1;
    font-family: $baseFont;
  }
  .ant-form-item {
    margin-bottom: 32px;
  }
  &__col {
    width: 540px;
    .ant-input {
      border: none;
      border-bottom: 1px solid $borderColor2;
      padding-left: 0;
      color: $grey;
      font-weight: normal;
      font-size: 14px;
      opacity: 1;
      font-family: $baseFont;
    }
    .ant-input-focused,
    .ant-input:focus {
      box-shadow: none;
    }
  }
  &__options {
    margin-top: 55px;
    margin-bottom: 50px;
    &_title {
      color: $darkText2;
      text-transform: uppercase;
      opacity: 1;
      font-family: $baseFont2;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 25px;
    }
    &_text {
      color: $grey;
      opacity: 1;
      font-weight: bold;
      font-size: 16px;
      font-family: $baseFont;
    }
    &_comments {
      opacity: 1 !important;
      font-weight: normal !important;
      color: $darkText2 !important;
      font-size: 14px !important;
    }
  }
}
