@import "../../../_var.scss";

.exclusive {
  &__main {
    max-width: 790px;
    margin: 0 auto;
  }

  &__tabs {
    .ant-tabs-nav {
      margin-bottom: 32px;
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      &::before {
        border-bottom: 1px solid $boxBg;
      }
    }
    .ant-tabs-tab {
      padding: 0 0 10px;
      color: $color-ba;
      font-size: 14px;
      font-weight: 700;
      font-family: $baseFont2;
      @media (max-width: 767px) {
        font-size: 10px;
        font-weight: 500;
        padding: 0 0 5px;
      }
      &:hover,
      &:focus {
        color: $blackfade;
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $blackfade;
        }
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 50px;
      @media (max-width: 767px) {
        margin: 0 0 0 15px;
      }
    }
    .ant-tabs-ink-bar {
      background: $defult-500;
    }
  }
}

// tabs content
.tabs {
  &__content {
    h3 {
      font-size: 20px;
      font-weight: 500;
      font-family: $baseFont2;
      margin-bottom: 25px;
      align-items: center;
      @media (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 15px;
      }
      @media (max-width: 467px) {
        font-size: 12px;
      }
    }

    .add-btn {
      cursor: pointer;
      padding: 6px 17px;
      font-size: 16px;
      font-weight: 500;
      color: $darkText2;
      background-color: $white;
      border: 1px solid $borderColor2;
      border-radius: 5px;
      font-family: $baseFont;
      transition: all linear 0.2s;

      @media (max-width: 767px) {
        font-size: 13px;
        padding: 5px 10px;
      }

      svg {
        display: inline-block;
        margin-right: 5px;
      }
      &:hover {
        border-color: $defult-500;
        color: $defult-500;

        svg {
          transition: all linear 0.2s;
          path {
            fill: $defult-500;
          }
        }
      }
    }

    .inputField__wrap {
      margin-bottom: 0;
      .inputField__input {
        input {
          padding-left: 30px;
          background-image: url(../../../Assets/image/svgimages/searchIcon.svg);
          background-repeat: no-repeat;
          background-position: center left 5px;
        }
      }
    }
    .custom_table {
      &.ui.table {
        margin-top: 0;
        border-spacing: 0 8px;
        color: $blackfade;

        th,
        td {
          padding: 12px 16px;
          white-space: nowrap;
        }

        th {
          font-size: 12px;
        }
        td {
          font-size: 14px;
          font-weight: 400;
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;
          @media (max-width: 767px) {
            justify-content: flex-start;
          }
        }
        .deleteIcon {
          padding-right: 15px;
        }
        .light-text {
          color: $grey;
        }
        .fw-600 {
          font-weight: 600;
          cursor: pointer;
        }
        .clickable-icon {
          display: inline-flex;

          &--disable {
            cursor: not-allowed;
            opacity: 0.2;
          }

          &:hover {
            svg {
              transition: all linear 0.2s;
              path {
                fill: $defult-500;
              }
            }
          }
        }
      }
    }
  }
}

.disabled-link {
  pointer-events: none;
}
