@import "../../../../_var.scss";

.Unread {
  .btnDeleteRead {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-bottom: 49px;

    &::before {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      border-bottom: 1px solid $borderColor2;
      content: "";
      margin-bottom: 17px;
    }

    .read_btn,
    .dlt_btn {
      margin-right: 10px;
      border: none;
      background: $whiteText;
      color: $grey;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 500;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        color: $black;
      }

      svg {
        margin-right: 10px;

        path {
          &:hover {
            color: $black;
          }
        }
      }
    }

    .dlt_btn {
      margin: 0;
    }
  }
}

.MessagePagi {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
