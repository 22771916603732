@import "../../../_var.scss";

.decline__modal {
  &--content {
    .inputField__wrap {
      position: relative;
      margin-bottom: 16px;
      .inputField__LimitMsg {
        margin-top: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  &--head {
    margin-bottom: 44px;
    h3 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 8px;
      color: #21040c;
    }
    p {
      font-size: 14px;
      color: #6f6f6f;
      margin-bottom: 0;
    }
  }

  &--reason {
    h2 {
      font-family: $baseFont2;
      font-size: 18px;
      font-weight: 600;
      color: #21040c;
      margin-bottom: 32px;
    }
  }
  &--reasonitem {
    padding-left: 0;
    li {
      margin-bottom: 24px;
    }
    .radioBtn_style {
      &.ui.radio.checkbox {
        label {
          text-transform: capitalize;
          font-size: 13px;
          font-weight: 400;
          color: #21040c;
          padding-left: 38px;
          padding-top: 5px;
          &::before {
            border: 1px solid #707070;
            width: 24px;
            height: 24px;
            background-color: #fff !important;
          }
        }

        input[type="radio"] {
          width: 24px;
          height: 24px;
        }

        &.checked {
          label {
            &::before {
              border: 1px solid $defult-500;
              //   background-color: $defult-500 !important;
              width: 22px;
              height: 22px;
            }
            &::after {
              border: none;
              background: $defult-500 !important;
              width: 16px !important;
              height: 16px !important;
              top: 12px;
              left: 11px;
              transform: translate(-50%, -50%);
            }
          }
          .box:after,
          label:after {
            border: 1px solid $defult-500;
            // background-color: $defult-500;
            width: 24px;
            height: 24px;
          }
          input:checked ~ label:after {
            // background-color: rgba(0, 0, 0, 0.95) !important;
          }
        }
      }
    }
  }
}

.SpacingRadio {
  width: 100%;
}
