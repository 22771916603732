@import '../../../../../var';

.dropCollectionDropIds {
  &.ant-select-multiple {
    .ant-select-selector {
      padding: 8px 10px 12px 0;
    }

    .ant-select-selection-search {
      margin-inline-start: 0;
    }

    .ant-select-selection-placeholder {
      left: 0;
    }

  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: unset;
  }

  &.ant-select:not(.ant-select-disabled) {

    &:hover,
    &:focus {

      .ant-select-selector {
        border-color: $defult-500;
      }
    }
  }

}

.inputField__checkbox {
  margin-right: 10px;
}

.dropCollectionUrlWrapper {
  margin-top: -20px;
  margin-bottom: 32px;
}