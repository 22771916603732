@import "../../_var.scss";

.ui.button.btnCustom {
  font-family: $baseFont;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 8px;
  background: $navTextHoverColor;
  font-size: 14px;
  color: $white;
  font-weight: 400;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s;
  border: 0px;
  box-shadow: none;

  &.minimal {
    background: $white;
    color: #6F6F6F;
    border: 0px;

    svg {
      path {
        fill: $grey;
      }
    }
  }

  svg {
    path {
      fill: $white;
    }
  }

  &:hover {
    color: $white;
    background: $darkButton;

    svg {
      path {
        fill: $white;
      }
    }
  }

  // &:focus {
  //   color: $white;
  //   background: $darkButton;
  // }

  &:disabled {
    color: $white !important;
    background: $darkButton !important;
    cursor: not-allowed !important;

    &:hover {
      cursor: not-allowed !important;
    }
  }

  .leftIcon {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.lesspadding {
  padding-left: 10px;
  padding-right: 12px;
}

.minimal {
  background: $white;
  color: $grey;
  border: 0px;

  svg {
    path {
      fill: $grey;
    }
  }
}

.addCalleryBtn {
  background: $white;
  color: $grey;
}

.borderBtn {
  cursor: pointer;
  background: $white;
  border: 1px solid $borderColor2;
  border-radius: 5px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $darkText2;
  text-transform: capitalize;
  padding: 8px 16px;
  transition: all 0.25s;
  // margin-bottom: 10px;

  &:hover {
    border-color: $darkButton;
  }

  img {
    margin-right: 16px;
  }
}