@import "../../../../../../../_var.scss";

.editStatusModal {
  max-width: 560px;
  width: 100%;

  .header {
    text-align: center;
  }

  .double-confirmation-text {
    text-align: center;
    margin-bottom: 20px;
  }

  .btns-spacing {
    display: flex;
    justify-content: center;
    gap: 10px;

    .button {
      margin: 0 !important;
    }

    .saveBtn {
      svg {
        margin-left: 10px;
      }
    }
  }

  .warning-message {
    text-align: center;
    margin-bottom: 20px;
    color: $diclined;
  }
}
