.DashBoard {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    text-align: left;
    letter-spacing: 0px;
    color: #23262c;
    font: normal normal normal 22px/28px;
    font-family: 'Lora', serif;
    font-weight: 500;
    margin: 0;
  }
}

.column {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  .cards {
    padding: 1px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 30px;
    background: transparent linear-gradient(269deg, rgba(147, 32, 64, 0.2) 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    height: 100%;

    .bg_Colour {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);

      .texting {
        width: 100%;

        h2 {
          text-align: left;
          font: normal normal 600 30px/38px;
          font-family: 'Lora', serif;
          letter-spacing: 0px;
          color: #4d4f5c;
          margin: 0;
        }

        p {
          text-align: left;
          font: normal normal normal 15px/25px;
          font-family: 'Lora', serif;
          letter-spacing: 0px;
          color: #6a7284;
          margin-bottom: 10px;
        }

        h6 {
          display: flex;
          align-items: center;
          text-align: left;
          letter-spacing: 0px;
          color: #932040;
          text-transform: capitalize;
          font: normal normal normal 14px/18px;
          font-family: 'Lora', serif;
          margin: 0;

          img {
            margin-right: 10px;
            width: auto;
          }
        }
      }

      .flow_chart {
        display: flex;
        align-items: center;
        width: 100%;

        img {
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}