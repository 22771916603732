@import "../../../../_var.scss";

.ui.modal.Wineft__modal {

  .content {
    padding: 20px !important;

    .Wineft__modal--content {
      min-height: 100px;

      .Wineft__modal--head {
        h3 {
          font-size: 24px;
          font-weight: 600;
          font-family: $baseFont2;
        }

        p {
          color: $darkText2;
          font-size: 16px;
          font-weight: 400;
          font-family: "Lora", serif;
        }
      }
    }
  }
}