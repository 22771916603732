@import "../../../../_var.scss";

.w-100 {
  width: 100%;
  max-width: 100%;
}

.bottle-files-handle {
  .profilePic-inner {
    flex: 0 0 100%;
  }

  .file-input__prof {
    width: 100%;
    height: 250px;
  }

  .file-input__image {
    margin-bottom: 10px;

    &-subheader {
      margin-bottom: 5px;
    }

    p {
      margin: 0;
      color: $color-67;
      font: normal normal normal 10px/13px $baseFont;
    }
  }

  .uploadField {
    margin-bottom: 15px;
    position: relative;
    width: 190px;
    height: 250px;

    .editand_DltBtn {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 11;

      .EditBtnsIcon,
      .DeltBtnsIcon {
        background-color: #fbfbfb;
        border: 1px solid #e7e7e7;
        margin-right: 10px;
        padding-bottom: 3px;
        cursor: pointer;
        border-radius: 5px;
        min-width: 30px;

        svg {
          vertical-align: middle;
        }

        &:hover {
          background: #932040;
          border: 1px solid #932040;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }

      .DeltBtnsIcon {
        margin: 0 !important;
      }
    }
  }
}

.range-slider-outer {
  max-width: 540px;
  width: 100%;
}

.winemakers-main {
  &.crtbtl {
    .block {
      max-width: 540px;
      width: 100%;
    }

    > .right {
      .genBottle_Id {
        margin-bottom: 17px;

        .borderBtn.genId_btn {
          flex: 0 0 auto;
        }
      }

      @media (max-width: 1679px) {
        padding-left: 15px;
      }
    }

    .crtbtl-left {
      @media (min-width: 1366px) {
        padding-right: 15px;
      }

      .text-right {
        padding-top: 25px;
        text-align: right;
      }

      .blocks-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .crtbtl-right {
      padding: 0 15px;

      .inner {
        max-width: 400px;
        width: 100%;

        .Header {
          padding-bottom: 50px;
        }
      }

      @media (min-width: 1366px) {
        border-left: 1px solid $boxBg;

        .inner {
          margin: 0 auto;
        }
      }
    }

    @media (max-width: 1365px) {
      .rightrow {
        > .ant-col {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    &.edt {
      @media (max-width: 991px) {
        > .right {
          .back-btn {
            display: none;
          }
        }
      }
    }
  }

  &.req-btl {
    > .right {
      padding: 0;

      .block {
        max-width: 100%;
      }

      .genBottle_Id {
        .borderBtn.genId_btn {
          flex: 0 0 auto;
        }
      }

      .btns-spacing {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;

        button.ui.button {
          min-width: inherit;
          margin: 15px 0 0 0;
        }
      }
    }
  }

  .dot-bar {
    position: relative;

    &:before {
      content: "";
      height: 2px;
      width: 42px;
      position: absolute;
      left: -60px;
      top: 11px;
      background: $navTextHoverColor;
    }

    &:after {
      content: "";
      height: 8px;
      width: 8px;
      position: absolute;
      background: $navTextHoverColor;
      left: -15px;
      border-radius: 50%;
      top: 8px;
    }

    &.dot-bar-grey {
      &:after,
      &:before {
        background-color: $greydark;
      }
    }

    @media (max-width: 1199px) {
      &:before {
        content: none;
      }
    }

    @media (max-width: 991px) {
      &:after {
        left: -10px;
        top: 8px;
        height: 5px;
        width: 5px;
      }
    }

    &.dot-bar-center {
      &:after,
      &:before {
        transform: translateY(-50%);
        top: 50%;
      }
    }
  }

  .edit_Bar {
    position: relative;

    &:before {
      content: "";
      height: 2px;
      width: 42px;
      position: absolute;
      left: -60px;
      top: 11px;
      background: $greydark;
    }

    &:after {
      content: "";
      height: 8px;
      width: 8px;
      position: absolute;
      background: $greydark;
      left: -15px;
      border-radius: 50%;
      top: 8px;
    }

    &.dot-bar-grey {
      &:after,
      &:before {
        background-color: $greydark;
      }
    }

    @media (max-width: 1199px) {
      &:before {
        content: none;
      }
    }

    @media (max-width: 991px) {
      &:after {
        left: -10px;
        top: 8px;
        height: 5px;
        width: 5px;
      }
    }

    &.dot-bar-center {
      &:after,
      &:before {
        transform: translateY(-50%);
        top: 50%;
      }
    }
  }

  .inputField__wrap {
    &.dot-bar {
      position: relative;

      &:before {
        top: 10px;
      }

      &:after {
        top: 7px;
      }

      @media (max-width: 991px) {
        &:after {
          top: 7px;
        }
      }
    }
  }

  > .right .block .Header {
    margin-bottom: 20px;
  }
}

.spcificLine {
  background: #000;
  width: 100%;
}

.BtnRadio {
  margin-bottom: 32px;
}

.btnSetRadio {
  margin-right: 30px;

  label {
    text-transform: capitalize !important;
    font-family: $baseFont;
    color: #130207 !important;
  }
}

.descriptList {
  position: relative;

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 70px;
  }

  .InfoListing {
    position: absolute;
    z-index: 1;
    top: -63px;
    left: 100%;
    max-width: 295px;
    margin-left: 80px;
    width: 100%;
    background: #fffdd0 0% 0% no-repeat padding-box;
    border: 1px solid #eee75b;
    padding: 15px;

    @media (min-width: 1921px) {
      margin-left: 50%;
    }

    @media (min-width: 2301px) {
      margin-left: 80%;
    }

    @media (max-width: 1599px) {
      margin-left: 40px;
    }

    @media (max-width: 1365px) {
      position: relative;
      z-index: 1;
      top: 0;
      left: 0;
      max-width: 100%;
      margin-left: 0px;
      margin-bottom: 20px;
    }

    .inputField__label {
      font-size: 14px;
      color: #23262c;
    }

    p {
      font-size: 12px;
    }
  }

  .InfoEdutBottle {
    position: absolute;
    z-index: 1;
    top: -63px;
    left: 100%;
    max-width: 398px;
    min-height: 170px;
    margin-left: 80px;
    width: 100%;
    background: #fffdd0 0% 0% no-repeat padding-box;
    border: 1px solid #eee75b;
    padding: 28px 24px;

    @media (min-width: 1921px) {
      margin-left: 50%;
    }

    @media (min-width: 2301px) {
      margin-left: 80%;
    }

    @media (max-width: 1599px) {
      margin-left: 40px;
    }

    @media (max-width: 1365px) {
      position: relative;
      z-index: 1;
      top: 0;
      left: 0;
      max-width: 100%;
      margin-left: 0px;
      margin-bottom: 20px;
    }

    .inputField__label {
      font-size: 16px;
      color: #23262c;
    }

    p {
      font-size: 14px;
    }
  }

  .AdditontRanges {
    top: -15px !important;
  }
}
