@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");

$baseFont: "Cinzel", serif;
$secFont: "Lora", serif;
$navTextColor: #23262c;
$black: #21040c;
$white: #ffffff;
$navHover: rgba(147, 32, 64, 0.15);
$navTextHoverColor: #932040;
$darkButton: #541225;
$defult-500: #932040;
$Dark-600: #751a34;
$Light-400: #c92c57;
$Light-300: #d74770;
$light: #ffffff;
$lightGrey: #e7e7e7;
$darkGray: #6f6f6f;
$midGray: #beb8ba;
$mainBlack: #21040c;
$dark-700: #5a47ff;
$DArk-900: #968882;
$green-700: #4f9e33;
$yellow-600: #daea64;
$unnamedColor: #787567;
$blackDark: #130207;
$notify: #f7502a;
$lightBg: #f4f8fb;
$darkGrey: #6f6f6f;
$lightText: #787567;
$footerBg: #120207;
$footercopyRightBg: #17070b;
$footerTextColor: #ffffff;
$active: #e27997;
$highlight: #968882;
$buttonshadow: -1px 6px 16px -9px #932040;
$border: #e2e2e2;
$lightbg: #fafcff;
$borderClr: #ededed;
$olive: #c9d6cb;
$lightPurple: #e0ddff;
$lightOpacity: rgba(224, 221, 255, 0.5);
$lightRed: #c92c57;
$footerBg: #120207;
$footercopyRightBg: #17070b;
$footerTextColor: #ffffff;
$information: #e0ddff;
$winebg: #330000;
$linktext: #351fff;
$fieldBorder: #d9d9d9;
$helpBg: #eff3ef;
$filterBg: #eff3ef;
$priceFilterInputBg: #fafcff;
$priceFilterInputBorder: #f1f1f1;
$layoutShowcaseBg: #fafcff;
$cardBorder: #ededed;
$borderAboutPage: #f4cdd8;
$walletNotificationBg: #f9fbfd;
$minimalBorder: #f5f5f5;
$Light-100: #f7f7ff;
$focusBorder: #f6d9e1;
// New variables
$teaserbg: #005c95;
$ractangledivbg: #a399ff;
$rectbg: #e0ddff;
$progressBarBg: #feeed8;
$progressBarActive: #f97245;
$blogTextColor: #23262c;
$growingSectionBg: #eff3ef;
$signupLabelColor: #707070;
$pink-light: #e689a4;
$confirmBg: #fffdd0;
$confirmBorder: #eee75b;
$notificationSubTitle: #6d6d6d;
$justNowText: #afafaf;
$notificationBorder: #eeeeee;
$notificationSepratorColor: rgba(112, 112, 112, 0.08);
$wlogStatus: #908bb9;
$howItWorksLink: #1400cc;

$rangebg: #eaeaea;
$LinkNewtext: #40a9ff;
