.marketplace-table-row {
  text-transform: inherit !important;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  //&__nft-name {
  //  max-width: 230px;
  //}
  //
  //&__contract-address {
  //  max-width: 200px;
  //}
}
