@import "./_var.scss";

html {
  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  font-family: $baseFont !important;
  background-color: $mainBg !important;

  &.bg-white {
    background-color: $white !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loginBoxMain {
  width: 800px !important;
  padding: 50px 30px;
  box-shadow: 0px 0px 5px $whiteText;
  border-radius: 15px;
  background: #fff;
}

.loginBoxMain h3 {
  margin-bottom: 40px !important;
  color: #000;
  font-size: 30px;
  text-align: center;
}

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

.overlayDiv {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayDiv div {
  position: relative;
}

.form__field-error {
  color: red;
  font-size: 13px;
  margin-top: 5px;
}

.form__field-error-rf {
  color: red;
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.centerBox {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.IncomeTable {
  h2 {
    font-size: 22px;
    color: #000000 !important;
    font-weight: 700;
  }

  table {
    border: 0px !important;
    border-radius: 12px !important;
    background: transparent !important;

    thead {
      tr {
        th {
          font-size: 16px !important;
          font-weight: 600 !important;
          height: 65px !important;
          border-bottom: 0 solid #000 !important;
          text-align: left !important;
          padding: 0 30px !important;
          color: $whiteText !important;
          background: #000000 !important;

          &:first-child {
            border-radius: 15px 0 0 0 !important;
          }

          &:last-child {
            border-radius: 0 15px 0 0 !important;
          }

          @media (max-width: 1250px) {
            font-size: 14px !important;
            padding: 0 20px !important;
          }

          @media (max-width: 991px) {
            padding: 0 15px !important;
            font-weight: 600 !important;
            height: 55px !important;
          }
        }
      }
    }

    tbody {
      padding: 20px 0;

      tr {
        td {
          color: $whiteText !important;
          font-size: 15px !important;
          font-weight: 400 !important;
          height: 50px !important;
          background: $boxBg !important;
          border-top: 0px solid #364458 !important;
          border-bottom: 0 !important;
          padding: 0 30px !important;
          text-align: left !important;

          @media (max-width: 1250px) {
            font-size: 13px !important;
            padding: 0 20px !important;
          }

          @media (max-width: 991px) {
            padding: 0 15px !important;
            height: 40px !important;
            white-space: nowrap !important;
          }

          img {
            margin-right: 5px;
          }

          &.bouts {
            color: #3fccef !important;
          }

          &.act {
            color: #00a900 !important;
          }

          &.inactive {
            color: #ff0000 !important;
          }
        }

        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 15px !important;
            }

            &:last-child {
              border-radius: 0 0 15px 0 !important;
            }
          }
        }
      }
    }

    tfoot {
      tr {
        background: transparent !important;
        border-bottom: 0px solid #000;

        td {
          background: transparent !important;
          font-size: 20px;
          font-weight: 700;
          border-top: 0px solid #000;
          height: 35px !important;

          .total {
            color: #f67200 !important;
          }

          a {
            background: #d73852;
            font-size: 12px;
            font-weight: 700;
            color: $whiteText;
            text-align: center;
            border-radius: 5px;
            display: block;
            margin: 0 auto;
            width: 160px;
            padding: 8px 0;
            margin-top: -20px;

            @media (max-width: 980px) {
              font-size: 18px !important;
              padding: 12px 0;
            }
          }
        }
      }
    }
  }

  .ui.pagination.menu {
    border-color: #000 !important;
    background: #000 !important;

    a {
      background: #101010;
      color: #fff;

      &.active {
        background: linear-gradient(to right, #1c8ee3, #41cff0) !important;
        color: #fff !important;
      }

      &:hover {
        background: linear-gradient(to right, #1c8ee3, #41cff0) !important;
        color: #fff;
      }
    }
  }
}

.nav-item.nav-link {
  color: $whiteText !important;
  font-size: 15px !important;
  border: 0px !important;
}

.nav-item.nav-link:focus,
.nav-item.nav-link:hover {
  color: $whiteText !important;
  background: linear-gradient(to right, #937a34, #cab069);
}

.nav-item.nav-link.active {
  background: linear-gradient(to right, #937a34, #cab069);
  color: $whiteText !important;
}

.nav-item.nav-link:hover {
  font-size: 16px;
  font-weight: 500 !important;
  border: none !important;
  background-color: #c4a910;
  outline: none;
}

.sorting {
  display: flex;
  align-items: center;
}

.create-nft-form {
  padding-top: 25px;

  .field {
    margin-bottom: 20px !important;
  }
}

td {
  cursor: pointer;
}

button.ui.button.addButton {
  // background-color: $main;
  // color: white;
  // font-size: 16px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  // background:$borderColor;
  border: 0px solid $borderColor;
  height: 40px;
  border-radius: 5px;
  color: $whiteText;
  background-image: $gradient;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-image: $gradientHover;
  }
}

.error-msg {
  color: red;
  margin-top: 5px;
}

.common-heading {
  font-size: 22px;
  font-weight: 500;
  font-family: $baseFont2;
  margin-bottom: 40px;
  line-height: 20px;
  color: #23262C;

  @media (max-width: 991px) {
    font-size: 18px;
  }
}

.strategy__titleDesc {
  color: #6f6f6f;
  font-family: Lora, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
}

.common-sub-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  font-family: $baseFont2;

  @media (max-width: 1279px) {
    font-size: 16px;
  }

  @media (max-width: 991px) {
    font-size: 15px;
  }
}

.common-heading-3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  font-family: $baseFont;

  @media (max-width: 1279px) {
    font-size: 15px;
  }

  @media (max-width: 991px) {
    font-size: 14px;
  }
}

.back-btn {
  margin-bottom: 20px;
  color: $grey;
  font-size: 12px;
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: $black;

    svg {
      fill: $black;
    }
  }

  @media (max-width: 479px) {
    span {
      display: none;
    }
  }
}

.ui.table {
  background-color: transparent;

  td {
    background-color: $white;
  }
}

.ui.pagination.menu {
  background: transparent;

  .item {
    display: grid;
    min-width: auto;
    min-height: auto;
    width: 32px;
    height: 32px;
    padding: 2px !important;
    font-size: 16px;
    border-radius: 4px;
    background-color: $white;

    &[type="prevItem"],
    &[type="nextItem"] {
      color: transparent;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
    }

    &[type="prevItem"] {
      background-image: url(./Assets/image/arrow-left.svg);
    }

    &[type="nextItem"] {
      background-image: url(./Assets/image/arrow-right.svg);
    }
  }
}

.p-0 {
  padding: 0 !important;
}

.inputField__label {
  +.react-datepicker-wrapper {
    margin-top: 12px;
  }
}

.react-datepicker__input-container {
  input {
    width: 250px;
    padding: 4px 10px;
    border: 1px solid #e7e7e7;

    &:focus,
    &:active {
      border-color: $defult-500;
    }
  }
}

.react-datepicker__tab-loop {

  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    margin-left: -15px;
  }
}