@import "../../../../_var.scss";
.detail-tab {
  &_title {
    font-weight: 500;
    font-size: 40px;
    color: $darkText;
    opacity: 1;
    margin-bottom: 16px;
    font-family: $baseFont2;
    span {
      margin-left: 8px;
    }
  }
  &_wineName {
    color: $darkText2;
    opacity: 1;
    font-family: $baseFont2;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 24px;
  }
  &_statusOrder {
    margin-bottom: 24px;
  }
  &_ownerTitle {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;
    color: $greyText;
    font-family: $baseFont;
  }
  &_ownerName {
    color: $greyText;
    font-weight: bold;
    font-size: 24px;
    font-family: $baseFont;
    margin-bottom: 70px;
    border-bottom: 1px solid $borderColor2;
    width: 675px;
    padding-bottom: 24px;
  }
  &_tabs {
    width: 675px;
  }
}
