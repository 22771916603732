@import "../../../_var.scss";

input,
button {
  outline: none !important;
}

.loginContainer {
  height: 100vh;
  background: #f4f8fb;
  display: flex !important;
  align-items: center;
  justify-content: center;

  @media (max-width: 1400px) {
    .ui.container {
      @media (max-width: 767px) {
        width: 98%;
      }
    }
  }

  &.ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ui.input {
    width: 100%;
  }
}

.main_loginForm {
  .loginForm {
    margin-top: 20px;
    box-shadow: none;
    border-radius: 10px;
    overflow: hidden;

    p.login-with-text-style {
      font-size: 16px;
      text-align: center;
      color: $darkText;
    }
  }

  .logoStyle {
    margin-bottom: 30px;
    width: 280px;

    @media (max-width: 1400px) {
      margin-bottom: 6px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
      width: 150px;
    }
  }

  .loginformBox {
    padding: 40px 80px;
    background: $white;
    // border:1px solid $borderColor;
    display: flex;
    max-width: 650px;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    color: $darkText;

    @media (max-width: 1400px) {
      padding: 30px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    p.toViewAccountStyle {
      text-align: center;
      color: $darkText;
    }

    h2 {
      color: $darkText;
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 30px;

      @media (max-width: 1400px) {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        margin-top: 5px;
      }

      .sub.header {
        margin-top: 15px;
        color: $darkText;
        font-size: 16px;
        text-transform: none;

        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
    }

    .form {
      width: 100%;

      .field {
        &.reg {
          input {
            text-align: left;
          }
        }

        label {
          font-size: 14px;
          margin-bottom: 10px;
          font-weight: 500;
          color: $darkText;
        }

        input,
        select {
          font-size: 16px;
          color: $darkText;
          background: transparent;
          text-align: left;
          border-radius: 5px;
          height: 52px;
          border: 1px solid $borderColor !important;

          &:focus,
          &:hover {
            color: $darkText;
            border: 1px solid $borderColor !important;
          }

          &::placeholder {
            color: $darkText;
          }

          &:autofill {
            box-shadow: 0 0 0 0px ivory inset !important;
            color: $darkText !important;
            background: transparent !important;
            border: 1px solid $borderColor !important;
          }

          &:autofill:focus,
          &:autofill:hover {
            box-shadow: 0 0 0 0px ivory inset !important;
            color: $darkText !important;
            background: transparent !important;
            border: 1px solid $borderColor !important;
          }
        }

        select {
          option {
            background: transparent !important;
            color: #000 !important;
            height: 45px !important;
            padding: 5px 15px !important;
          }

          &:focus,
          &:hover,
          &::placeholder {
            border-color: $borderColor !important;
          }
        }

        &.loginBtn {
          text-align: center;

          &.automatic {
            margin-bottom: 25px;
          }

          button {
            margin-top: 10px;
            cursor: pointer;
            font-size: 16px;
            width: 280px;
            text-transform: uppercase;
            font-weight: 600;
            // background:$borderColor;
            border: 0px solid $borderColor;
            height: 52px;
            border-radius: 5px;
            color: $whiteText;
            background-image: $gradient;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1),
              0 2px 6px rgba(0, 0, 0, 0.1);

            &:hover {
              background-image: $gradientHover;
            }
          }
        }
      }
    }

    h4.totalUsers,
    h4.ui.header.totalUsers {
      margin-top: 106px;
      text-align: center;
      font-size: 16px;
      color: #acacac;
      font-weight: 400;

      .sub.header {
        margin-top: 15px;
        color: #2e77bc;
        font-size: 36px;
        text-transform: none;
      }
    }

    p {
      a {
        color: $borderColor;
      }
    }
  }

  .ui.message.messageTransaction {
    border-width: 0px;
    box-shadow: none;
    border-radius: 0;
    margin-top: 0;
    background-color: transparent;

    @media (max-width: 1400px) {
      padding: 0px;
    }

    .ui.message>.icon {
      margin-right: 0.2em;
    }

    @media (max-width: 1400px) {
      i.icon {
        margin-right: 10px;
      }
    }

    @media (max-width: 767px) {
      margin: 20px auto 10px;
      width: 300px;
    }

    .header {
      font-size: 18px;
      color: $darkText;
      font-weight: 400;

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

.msg {
  border-radius: 2px;
  padding: 8px;
  text-align: center;
  color: $darkText;
}

.scuess {
  border: 1px solid rgb(80, 235, 9);
  border-radius: 2px;
  padding: 8px;
  background: #fff;
  text-align: center;
}

.blockDiv {
  display: none;
}

.closeBtn {
  float: right;
  margin-right: 11px;
  color: $darkText;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.seleboxLogin {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    top: 24px;
    right: 18px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #113859;
  }

  select {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.849) !important;
    background: transparent !important;
    height: 50px !important;
    border: 1px solid #ddd !important;
    border-radius: 25px !important;
    font-weight: 400;
    float: right;
    text-align: left;
    appearance: none;

    @media (max-width: 767px) {
      font-size: 16px !important;
      min-width: 120px;
      padding: 10px 0;
      height: 42px !important;
      height: auto;
    }

    &.invest {
      color: $darkText;
      background: #d73852;
      border-radius: 6px;
      border: 1px solid #d73852;
    }
  }
}

.loginWarning {
  color: $darkText;
  text-align: center;
  margin-top: 30px !important;
}

.passlogin {
  position: relative;
}