@import "../../../../../../../_var.scss";

.editPriceModal {
  max-width: 560px;
  width: 100%;

  .header {
    text-align: center;
  }

  .double-confirmation-text {
    text-align: center;
    margin-bottom: 20px;
  }

  .btns-spacing {
    display: flex;
    justify-content: center;
    gap: 10px;

    .saveBtn {
      svg {
        margin-left: 10px;
      }
    }
  }

  .ant-input-number {
    border: 0;
    width: 100%;

    &.ant-input-number-focused {
      box-shadow: none;
      border: 0;
    }

    &:focus,
    &:focus-visible {
      border-radius: 0;
      box-shadow: none;
      border: 0;
    }

    .ant-input-number-out-of-range input {
      color: $darkText2;
    }
  }

  .warning-message {
    text-align: center;
    margin-bottom: 20px;
    color: $diclined;
  }

  input.ant-input-number-input {
    width: 100%;
    border-radius: 0;
    border: 0;
    padding: 17px;
    padding-left: 0;
    border-bottom: 1px solid $borderColor2;
    height: 53px;

    &:focus,
    &:focus-visible {
      border-radius: 0;
      box-shadow: unset;
      border-bottom: 1px solid $navTextHoverColor;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}
