@import "../../../../../_var.scss";

.listWinersButtons {
    .ui.visible.popup {
        background-color: #f4f8fb;
    }

    .ui.button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        background: $white;

        &:hover {
            background: #f4f8fb;
        }
    }
}