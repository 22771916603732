@import "./_var.scss";

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: $baseFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

body>iframe {
  display: none !important;
}

code {
  font-family: "Lora", serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $baseFont2;
}

h3.hd_title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  text-transform: uppercase;
}

#root {
  height: 100%;
}

.PrivateArea__content {
  height: 100%;
}

.infoData {
  padding-top: 5px;
  color: #555252;
}

.showPassoword_style {
  position: absolute;
  right: 20px;
  top: 55%;
  z-index: 10;
  cursor: pointer;
}

.rejecttext {
  width: 95%;
  margin: 16px;
  float: none;
  resize: none;
}

// Sorting DropDown

.SortingIcon {
  // min-width: 135px !important;

  .dropdown.icon {
    &::after {
      background-image: url("./Assets/image/dualArrow.svg") !important;
      background-size: 8px !important;
      width: 12px !important;
      height: 20px !important;
      top: 12px !important;
    }
  }
}

// common ui
.ui.selection.dropdown {
  border: 1px solid $greyBorder;
  border-radius: 8px;
  padding: 12px 15px;

  .dropdown.icon {
    border: 0;

    &::before {
      display: none;
    }

    &::after {
      background-image: url("./Assets/image/down_arrow.svg");
      background-repeat: no-repeat;
      background-size: 11px;
      padding: 0;
      margin: 0;
      width: 12px;
      font-size: 16px;
      content: "";
      position: absolute;
      height: 8px;
      right: 8px;
      top: 16px;
    }
  }


  .menu>.item {
    font-size: 12px;
    padding: 7px 5px !important;
  }

  &:not(.button)>.text,
  :not(.button)>.default.text {
    color: $grey;
  }

  &:hover {
    border-color: $greyBorder;
  }

  .visible.menu.transition {
    margin-top: 5px;
    border-color: #cbcbcb4d;
  }
}

.ui.active.visible.selection.dropdown {
  border-color: #c3295840;
}

.ui.table.custom_table {
  border: 0;
  // border-collapse: separate;
  border-spacing: 0 16px;

  th {
    min-width: 130px;
    background-color: transparent;
    color: $darkText;
    font: normal normal bold 12px/15px;
    font-family: 'Lora', serif;
  }

  .TableEditBtn:last-child {
    min-width: 0px;
    padding: 0;
  }

  tbody {
    tr {
      td {
        border: 1px solid $greyBorder;
        border-width: 1px 0 1px;
        text-transform: capitalize;
        font: normal normal normal 12px/15px;
        font-family: 'Lora', serif;

        &:first-child {
          border-left: 1px solid $greyBorder;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-right: 1px solid $greyBorder;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        .dropCollectionUrl {
          text-transform: none;
          color: blue;
        }
      }
    }
  }

  .action_area {
    button {
      margin-right: 15px;
    }
  }

  .ui.button {
    background-color: $white;
    padding: 5px 20px;
    font-family: $baseFont;
    height: 32px;
    font-weight: 400;
    color: $darkText;
    border: 1px solid $greyBorder;
    border-radius: 8px;

    &.btnArrow {
      background-color: $color-67;
    }

    &:hover {
      background-color: $navTextHoverColor;
      color: $white;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  span {
    &.table-status {
      display: inline-flex;
      background-color: $diclined;
      justify-content: center;
      align-items: center;
      padding: 7px 16px;
      border-radius: 8px;
      margin: 2px;
      font-size: 12px;
      font-weight: 500;
      color: $white;
      min-width: 100px;

      &.declined {
        background-color: $diclined;
      }

      &.pending {
        background-color: $yellow;
        color: $pendingtext;
      }

      &.received {
        background-color: $borderColor2;
        color: $blackfade;
      }

      &.published {
        background-color: $green;
      }

      &.sent {
        background-color: $orange;
      }
    }
  }

  a,
  button {
    &.action-btn {
      display: inline-flex;
      background-color: $white;
      justify-content: center;
      align-items: center;
      padding: 6px 17px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 500;
      color: $darkText2;
      transition: 0.3s all ease-in-out;
      border: 1px solid $borderColor2;
      margin: 3px 5px;

      svg {
        margin-right: 10px;
        vertical-align: middle;

        path {
          fill: $darkText2;
        }
      }

      &:hover {
        border-color: $borderColorLight;
      }
    }

    // &.act-dlt{

    // }
    // &.act-add{

    // }
  }
}

.table_pagi {
  @media (min-width: 1200px) {
    text-align: right;
  }

  &.new {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    border-top: 1px solid $greylight;
    padding-top: 20px;
    margin-top: 5px;
    margin-bottom: 20px;

    @media (max-width: 1199px) {
      flex-wrap: wrap;
      justify-content: center;

      .pagination.menu {
        flex: 0 0 100%;
        margin-top: 15px;
        justify-content: center;
      }
    }

    .ui.selection.dropdown {
      min-width: 44px !important;
      min-height: auto;
      height: 32px;
      padding: 5px 5px;
      line-height: 20px;
      font-size: 12px;

      .dropdown.icon {
        right: 2px;
        top: 4px;
      }
    }
  }
}

.pagination.menu {
  box-shadow: unset;
  border: 0;

  .item {
    margin: 0 5px;
    border: 1px solid $greyBorder;
    border-radius: 8px;

    &::before {
      display: none;
    }

    &:first-child,
    &:last-child {
      border-radius: 8px !important;

      &:hover {
        background-color: $borderColor;
        opacity: 0.4;
      }
    }

    &.active {
      background-color: $navTextHoverColor !important;
      color: $white !important;
    }
  }
}

.labelStyle {
  display: block;
  font-size: 13px;
  font-weight: 700;
  color: $darkText2;
  margin-bottom: 15px;
}

.lineStyle {
  margin: 84px 0;
  border: 0;
  height: 1px;
  background: $borderColor2;

  @media (max-width: 1279px) {
    margin: 50px 0;
  }
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
  color: $darkText;
}

.subText {
  font-size: 16px;
  font-weight: 500;
  color: $grey;
}

.mb30 {
  margin-bottom: 32px;
}

.mb25 {
  margin-bottom: 25px;
}

.dflex {
  display: flex;
  justify-content: space-between;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"],
.ui.input>input {
  font-family: inherit;
}

.ui.card>.content>.header,
.ui.cards>.card>.content>.header {
  font-family: inherit;
}

.w-100 {
  width: 100% !important;
}

.uploadField {
  margin-bottom: 15px;
  position: relative;
  width: 100%;


  .editand_DltBtn {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 11;

    .EditBtnsIcon,
    .DeltBtnsIcon {
      background-color: #fbfbfb;
      border: 1px solid #e7e7e7;
      margin-right: 10px;
      padding-bottom: 3px;
      cursor: pointer;
      border-radius: 5px;
      min-width: 30px;

      svg {
        vertical-align: middle;
      }

      &:hover {
        background: #932040;
        border: 1px solid #932040;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .DeltBtnsIcon {
      margin: 0 !important;
    }
  }
}

.editImage {
  width: 175px !important;
  margin: 0 auto !important;

  img {
    width: 64px !important;
  }
}

@media (max-width: 450px) {
  .ui.pagination.ListPagi.menu {
    .item {
      max-width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2rem;
    }
  }
}

// helper classes

.hidden {
  display: none !important;
}

.no-pointers {
  pointer-events: none;
}

.d-flex {
  display: flex;
}

.full-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.j-center {
  justify-content: center;
}

.j-between {
  justify-content: space-between;
}

.d-column {
  flex-direction: column;
}


.hidden-mobile {
  @media (max-width: 991px) {
    display: none;
  }
}

.hidden-desktop {
  @media (min-width: 992px) {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.ant-modal-body {
  overflow: auto;
}

.error {
  margin-top: 4px;
  font-size: 13px;
  color: red;
}