@import "../../../_var.scss";

.hideshow {
  position: absolute;
  right: 23px;
  top: 34px;
  font-style: italic;
}

.hide {
  position: relative;
}

.filterComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchBar {
  text-align: center;
}

.filltersUser {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .filltersTop {
    width: 100%;
    display: flex;

    .ui.icon.labeled.input {
      width: 50%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .filltersBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .ui.dropdown {
      margin-right: 20px;
    }

    .ui.primary.button {
      margin-right: 0px;
    }

    .search_fld {
      // width: 330px;
      input {
        height: 40px;
        font-family: $baseFont;
        border: 1px solid $greyBorder;
        border-radius: 8px;
        padding-left: 40px;

        &::placeholder {
          color: $grey;
        }
      }

      i.inverted.icon {
        color: $darkText;
        left: 4px;
        opacity: 0.8;
      }
    }

    .sorting {
      display: flex;
      align-items: center;
      margin-left: 10px;
      width: 100%;

      span {
        width: 100%;
        margin-right: 10px;
      }
    }

    .left_fill,
    .rightfill {
      display: flex;
      margin-top: 20px;
    }
    @media (max-width: 1199px) {
      .left_fill {
        margin-top: 0;
      }
    }
    .ui.selection.dropdown > .dropdown.icon {
      background: #fff;
      height: 100%;
      padding: 0;
      width: 25px;
      z-index: 1;
      opacity: 1;
    }
    .left_fill {
      > .ui.selection.dropdown {
        min-width: 140px;
        padding-right: 30px;
        .default.text {
          white-space: nowrap;
        }
        .menu {
          .item {
            span {
              white-space: nowrap;
            }
          }
        }
      }
      > .sorting {
        @media (min-width: 1200px) {
          flex: 0 0 190px;
          margin-right: 15px;
        }
        .ui.selection.dropdown {
          min-width: 100px;
          padding-right: 30px;
          .default.text {
            white-space: nowrap;
          }
          .menu {
            .item {
              span {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
    @media (max-width: 1199px) {
      flex-wrap: wrap;
      .left_fill {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        .ui.icon.input.search_fld {
          flex: 0 0 100%;
          width: 100%;
          max-width: 100%;
          margin-bottom: 15px;
        }
        .ui.selection.dropdown {
          order: 1;
        }
        .sorting {
          order: 2;
          span {
            text-align: right;
          }
        }
        .ui.selection.dropdown,
        .sorting {
          flex: 0 0 47%;
          max-width: 47%;
          margin: 0;
          @media (max-width: 575px) {
            flex: 0 0 100%;
            max-width: 100%;
            // margin-top: 15px;
            align-items: center;
            span {
              flex: 0 0 50px;
            }
            .ui.dropdown {
              flex: 1 0;
            }
          }
        }
        > .ui.selection.dropdown {
          @media (max-width: 575px) {
            margin-bottom: 15px;
          }
        }
      }
    }
    @media (max-width: 575px) {
      .rightfill {
        flex-wrap: wrap;
        .sorting {
          flex: 0 0 130px;
        }
        .ui.button {
          margin-top: 15px;
        }
      }
    }
  }
  .ui.button.btnCustom {
    white-space: nowrap;
  }
}

.autowidth.ui.selection.dropdown {
  min-width: 70px;
}
