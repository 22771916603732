.detail-tab_shippingOrderStatus {
  display: flex;
  width: 85px;
  height: 32px;
  border: 1px solid #EDEDED;
  border-radius: 8px;
  background-color: #E7E7E7;
  color: #23262C;
  align-items: center;
  justify-content: center;
}

.detail-tab_shippingOrderStatusDelivered {
  background-color: #E7E7E7;
}

.detail-tab_shippingOrderStatusShipped {
  background-color: #F97245;
  color: #fff;
}

.detail-tab_shippingOrderStatusPrepared {
  background-color: #EFE736;
}

.detail-tab_shippingOrderStatusStorage {
  background-color: #6DBB45;
  color: #fff;
}

.detail-tab_shippingOrderStatusPending {
  background-color: #EFE736;
}