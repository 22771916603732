@import "../../_var.scss";

.Spacing {
  margin-top: 15px;

  .messages {
    background: $white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 13px 24px 16px 10px;
    // border: 1px solid $grey30;
    border: 1px solid $borderRead;
    border-radius: 8px;

    @media (max-width: 599px) {
      flex-wrap: wrap;
      align-items: center;
    }

    .mail_Icon {
      margin-right: 16px;

      @media (max-width: 599px) {
        margin: 0;
        flex: 0 0 30%;
      }

      img {
        max-width: 36px;
      }
    }

    .containt {
      @media (max-width: 599px) {
        order: 1;
        margin-top: 15px;
      }

      p {
        font-size: 14px;
        color: $blackfade;
        font-weight: 400;
        margin-bottom: 0;
        margin-right: 32px;

        @media (max-width: 599px) {
          margin: 0;
        }
      }
    }

    .btnscus {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 599px) {
        flex: 0 0 70%;
        max-width: 70%;
      }

      h6 {
        font-size: 14px;
        color: $grey;
        margin-bottom: 0;
        margin-right: 37px;
      }

      .read_btn,
      .dlt_btn {
        background: transparent;
        border: 0;
        padding: 0;
        margin-right: 10px;
        font-size: 18px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .dlt_btn {
        margin: 0;
      }
    }
  }

  .Mailunread {
    // border: 1px solid $borderRead;
    // border-left: 5px solid $borderRead;
    border-radius: 8px;
    position: relative;

    &::before {
      content: "";
      width: 5px;
      height: 100%;
      position: absolute;
      background-color: $borderRead;
      top: 0;
      left: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
