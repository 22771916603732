@import "../../../_var.scss";

.CustomDropdown {
  .ui.multiple.search.selection.dropdown.Selected {
    width: 100%;
    padding: 10px 30px 10px 8px !important;
    font-size: 14px;
    color: #21040c;
    border: none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    height: 45px;

    .menu.transition.visible {
      overflow: hidden;
      max-height: unset !important;

      .ListingDropm {
        padding: 7px 18px;
        height: 160px;
        overflow-y: scroll;

        .item {
          margin: 10px 0;

          .ui.checkbox {
            label {
              font-family: $baseFont;
              color: #21040c;
              font-size: 14px;
              font-weight: 500;
            }
          }

          .ui.checked.checkbox {
            label {
              &::before {
                background: #8f1e41 0% 0% no-repeat padding-box;
              }

              &::after {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.NftWine_Modal {
  max-width: 900px !important;

  .ui.left.icon.input {
    width: 100%;
    input {
      border: none;
      border-bottom: 1px solid #e7e7e7;
    }
  }
  .header {
    background: transparent !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-size: 12px;
      color: #6f6f6f;
      background: transparent;
      border: none;
      cursor: pointer;
      font-weight: 600;
      font-family: $baseFont;
    }

    span {
      color: #6f6f6f;
      font-size: 12px;
      font-family: $baseFont;
      font-weight: 400;
    }
  }
  .ListingDropm {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex: 1 0;
    height: 500px;
    padding: 20px 0;
    overflow-y: scroll;
    .ItemList {
      flex: 30%;
      height: 40px;
    }
  }

  .FooterDrop {
    border-top: 1px solid #e7e7e7;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .BrderBtn {
      color: #21040c;
      font-size: 12px;
      font-family: $baseFont;
      font-weight: 500;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      min-width: 78px;
      height: 32px;
    }

    .AddAccept {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .BtnAccept {
        height: 32px;
        margin-left: 30px;
      }
    }
  }
  .BtnSendaccess {
    display: none !important;
  }
}
