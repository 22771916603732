@import "../../../_var.scss";

.horiz {
  margin: 80px 0;
  border: 1px solid $borderColor2;
}

.Step_Menu {
  width: 75%;
  margin-bottom: 30px;

  @media (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  .wallet_heading {
    margin-bottom: 20px;

    h3 {
      text-align: left;
      font: normal normal 22px/28px;
      font-family: 'Lora', serif;
      letter-spacing: 0px;
      color: #23262c;
      text-transform: capitalize;
      font-weight: 500;
    }
  }

  .list {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    height: 40px;
    border: 1px solid $greyBorder;
    border-radius: 8px;

    .item {
      margin-right: 11%;

      @media (max-width: 767px) {
        margin-right: 3%;
      }

      &::after {
        display: none;
      }
    }

    .active {
      background: #eaebfd 0% 0% no-repeat padding-box;
      border-radius: 8px;
      padding: 11px !important;
    }
  }
}

.steps_buuton {

  // display: flex;
  // justify-content: space-around;
  // margin-top: 40px;
  .previous {
    min-width: 173px;
    margin-right: 20px;
    margin-bottom: 25px;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #e7e7e7 !important;
    color: #21040c !important;
  }

  .Continue {
    min-width: 173px;
    margin-right: 20px;
    margin-bottom: 25px;
    background: #932040 0% 0% no-repeat padding-box;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
  }
}

.description {
  width: 75%;
  margin: 30px 0;
}

.text_Descri {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 30px 30px 80px;
}

.winemakers-main {
  display: flex;

  >.left {
    flex: 0 0 262px;
    max-width: calc(100% - 262px);
    border-right: 1px solid $borderColor2;
    position: relative;

    .ant-steps {
      // height: 100%;
      position: sticky;
      // position: sti;
      left: 0;
      top: 20px;
      z-index: 1;
      width: 100%;
      max-width: 262px;
      overflow-y: auto;

      .ant-steps-item-title {
        font-size: 14px;
        color: $greyLight;

        @media (max-width: 767px) {
          font-size: 10px;
        }

        @media (max-width: 575px) {
          display: none;
        }
      }

      .ant-steps-item-description {
        color: $grey;
        font-size: 16px;

        @media (max-width: 767px) {
          font-size: 12px;
        }

        @media (max-width: 575px) {
          display: none;
        }
      }

      .ant-steps-item-icon {
        width: 45px;
        height: 45px;
        line-height: 45px;
        border: 1px solid $borderColor3;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-steps-item-container {
        min-height: 115px;
        cursor: pointer;
      }

      .ant-steps-item-tail {
        left: 22.5px;
        top: 12px;
        height: calc(100% - 12px);

        &::after {
          background-color: transparent;
          border-right: 1px dashed $borderColor3;
          z-index: -1;
          position: relative;
        }
      }

      .ant-steps-item-finish {
        .ant-steps-item-icon {
          background-color: $stepactive;
          border-color: $stepactiveborder;

          svg {
            fill: $stepactiveborder;
          }
        }

        .ant-steps-item-tail {
          &::after {
            border-right: 1px solid $stepactiveborder;
          }
        }
      }

      .ant-steps-item-current {
        .ant-steps-item-icon {
          border: 1px solid $stepactiveborder;

          svg {
            path {
              fill: $stepactiveborder;
            }
          }

          .tikIcon {
            path {
              fill: none;
            }
          }
        }
      }
    }

    // @media (max-width: 1199px) {
    //     flex: 0 0 200px;
    //     max-width: calc(100% - 200px);
    // }

    @media (max-width: 1199px) {
      flex: 0 0 160px;
      max-width: calc(100% - 156px);
    }

    @media (max-width: 575px) {
      flex: 0 0 60px;
      max-width: calc(100% - 60px);
    }
  }

  >.right {
    flex: 1 0;
    padding-left: 60px;
    max-width: calc(100% - 262px);

    .block {
      max-width: 1090px;
      width: 100%;
      margin-bottom: 40px;
      padding-bottom: 20px;

      .inputField__wrap {
        max-width: 540px;
        width: 100%;
        position: relative;

        .inputField__LimitMsg {
          color: $grey;
          font-size: 12px;
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
        }

        @media (max-width: 991px) {
          max-width: 100%;
        }
      }

      div.Header {
        margin-bottom: 40px;

        p {
          font-size: 14px;
          color: $grey;
        }

        &.Header-about {
          margin-bottom: 24px;

          .heading {
            font-size: 18px;
            padding-bottom: 0;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $borderColor2;
      }

      .genBottle_Id {
        position: relative;
        display: flex;
        align-items: flex-end;
        margin-bottom: 32px;

        .inputField__wrap {
          margin-bottom: 0;
          flex: 1 0;
          // padding-right: 15px;
        }

        .borderBtn.genId_btn {
          flex: 0 0 157px;
        }

        @media (max-width: 575px) {
          flex-wrap: wrap;

          .borderBtn.genId_btn {
            margin-top: 15px;
          }
        }
      }

      .uploadField {
        .profilePic {
          display: flex;
          align-items: center;

          .profilePic-inner {
            position: relative;
            background-color: $lightgrey;
            border: 1px solid $borderColor2;
            border-radius: 15px;
            width: 100%;
            min-height: 180px;

            .input__input {
              position: absolute;
              left: 0;
              top: 0;
              height: 170px;
              width: 100%;
              opacity: 0;
              border-radius: 15px;
              z-index: 2;
              cursor: pointer;
            }

            .file-input__prof {
              background-color: transparent;
              position: absolute;
              margin: 0;
              border: 0;
              cursor: pointer;
              padding: 0;
              border-radius: 15px;
              z-index: 1;
              display: block;
              width: 100%;
              height: 100%;
              min-height: 120px;

              img[src=""] {
                display: none;
              }

              img {
                height: 100% !important;
                width: 100%;
                object-fit: contain;
                border-radius: 15px;
              }

              &::before {
                content: "";
                height: 64px;
                width: 64px;
                border-radius: 5px;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                background-color: $white;
                background-image: url(../../../Assets/image/upload-arrow-down.svg);
                background-repeat: no-repeat;
                background-size: 16px;
                background-position: center;
                border: 1px solid $borderColor2;
                z-index: 1;
              }
            }




          }

          .file-input__importent {
            padding-left: 30px;

            @media (max-width: 575px) {
              padding-left: 0;
            }

            p,
            strong {
              font-size: 14px;
              color: $grey;
            }
          }

          @media (max-width: 575px) {
            flex-wrap: wrap;

            .file-input__importent {
              flex: 0 0 100%;
              padding-top: 30px;
            }
          }
        }
      }

      .btns-spacing {
        padding-top: 50px;
        padding-bottom: 50px;

        button {
          margin: 15px 30px 0 0;
        }
      }

      &.border-0 {
        border: 0;
      }

      .padd-right {
        .inputField__wrap {
          @media (min-width: 992px) {
            padding-right: 100px;
          }
        }
      }
    }

    @media (max-width: 1199px) {
      padding-left: 20px;
      max-width: calc(100% - 160px);
    }

    @media (max-width: 575px) {
      max-width: calc(100% - 60px);
    }
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.cus_drop.inputField__wrap select {
  border-radius: 0;
  border: none;
  padding: 17px 17px 17px 0;
  border-bottom: 1px solid $borderColor2;
  font-size: 15px;
  font-weight: 400;
  color: $darkText;
  text-transform: capitalize;
  background-color: #fff;
  width: 100%;
  cursor: pointer;

  &:focus-visible {
    border: none !important;
    border-bottom: 1px solid $borderColor2 !important;
    outline: none;
    color: $darkText;
  }

  &.empty {
    color: $greylight;
  }
}

.ui.grid {
  .eight.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column.padd-right.InputSpaceing {
    width: 100% !important;
  }
}

.p-0 {
  padding: 0 !important;
}

// .PhoneInput {
//   display: flex;
//   align-items: center;
//   .PhoneInputCountry {
//     display: flex;
//     align-items: center;
//     position: relative;
//     .PhoneInputCountrySelect {
//       opacity: 0;
//       width: 70px;
//       position: relative;
//       z-index: 9;
//       cursor: pointer;
//     }
//     .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
//       position: absolute;
//       width: 30px;
//     }
//     &::before {
//       position: absolute;
//       content: "";
//       width: 20px;
//       height: 20px;
//       // background-color: red;
//       background-image: url(../../../Assets/image/down_arrow.svg);
//       background-size: 100%;
//       top: 0;
//       right: 0;
//       z-index: 2;
//     }
//   }
// }
.PhoneInputList {
  max-width: 540px;
  padding-bottom: 28px;

  label {
    display: block;
    font-size: 13px;
    font-weight: 700;
    color: #21040c;
    margin-bottom: 10px;
  }

  .PhoneInput {
    border-bottom: 1px solid #e7e7e7;

    .PhoneInputCountrySelectArrow {
      border-color: #000 !important;
      width: 8px;
      height: 8px;
      margin-left: 10px !important;
    }

    .PhoneInputInput {
      border: none !important;
      border-left: 1px solid #e7e7e7 !important;
      border-radius: 0 !important;
      margin: 0 0 5px 5px !important;
    }
  }
}

.file-input__prof.IconHidd {
  &::before {
    display: none;
  }
}

.file-input__prof.label_image {
  img {
    height: unset !important;
    width: unset !important;

    @media (max-width: 991px) {
      width: 64px !important;
    }
  }
}

.file-input__prof.BAckImageSet {
  img {
    height: unset !important;
    width: 100% !important;

    // @media (max-width: 991px) {
    //   width: 64px !important;
    // }
  }
}

.winemakers-main .sectionInfo-people {
  .heading {
    padding-bottom: 16px;
  }
}