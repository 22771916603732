@import "../../../_var.scss";

.dataPick {
  .ant-picker.ant-picker-borderless {
    padding: 0;
  }

  .ant-picker-date-panel {
    margin-top: 20px;
    width: 275px !important;
  }

  .ant-picker {
    padding: 20px 0;
  }

  .ant-picker-dropdown-hidden {
    display: flex;
  }

  &_settings {
    width: 256px;

    .ant-picker-dropdown-hidden {
      display: flex;
    }
  }
}

.hide-picker {
  display: none !important;

  & * {
    display: none !important;
  }
}

.detail-wrap {
  max-width: 1482px;
  margin: auto;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h3 {
      font-size: 22px;
      font-weight: 500;
      font-family: $baseFont2;
      margin: 0;
      color: $darkText;

      @media (max-width: 1366px) {
        font-size: 18px;
      }
    }
  }
}

.detail-card {
  background: $white;
  border: 1px solid $greyBorder;
  border-radius: 8px;
  display: flex;

  @media (max-width: 1366px) {
    flex-wrap: wrap;
  }

  &__profImg {
    width: 94px;
    height: 94px;
    border-radius: 50%;

    @media (max-width: 1366px) {
      width: 75px;
      height: 75px;
    }
  }

  &__item {
    padding: 16px 30px;
    display: flex;
    margin-bottom: 0;

    &--flex1 {
      flex: 0 0 25%;
    }

    &--flex2 {
      flex: 0 0 32%;
      border-left: 1px solid $borderColor2;
      border-right: 1px solid $borderColor2;

      @media (max-width: 1366px) {
        border-right: 0;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        border-left: 0;
        border-top: 1px solid $borderColor2;
      }
    }

    &--flex3 {
      flex: 1 0;

      @media (max-width: 1366px) {
        border-top: 1px solid $borderColor2;
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .pr-40 {
        padding-right: 15%;

        @media (max-width: 1679px) {
          margin: 0;
          padding-right: 0;
          margin-bottom: 10px;
        }
      }
    }

    &--flex1,
    &--flex2 {
      @media (max-width: 1366px) {
        flex: 0 0 50%;
      }

      @media (max-width: 767px) {
        flex: 0 0 100%;
      }
    }

    li {
      display: block;
      margin-bottom: 0;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }

      &:first-child {
        margin-right: 24px;
      }

      h3 {
        color: $darkText2;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        font-family: $baseFont2;
        margin-bottom: 8px;

        @media (max-width: 1366px) {
          font-size: 14px;
        }
      }

      h5 {
        font-size: 12px;
        font-weight: 500;
        color: $color-67;
        margin-bottom: 9px;

        @media (max-width: 1366px) {
          font-size: 10px;
        }

        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
      }

      p {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: $blackfade;

        @media (max-width: 1366px) {
          font-size: 12px;
        }
      }

      .fw-700 {
        font-weight: 700;
      }

      .borderBtn {
        border-radius: 5px;
        font-weight: 500;
        letter-spacing: 0;
        white-space: nowrap;
      }
    }
  }
}

.detail-tab {
  .filltersUser {
    font-family: $baseFont;

    .filltersBottom {
      gap: 15px 0;

      .left_fill,
      .rightfill {
        margin-top: 0;
      }

      .ui.selection.dropdown {
        @media (max-width: 1199px) {
          margin-bottom: 15px;
        }
      }

      .ui.selection.dropdown:not(.button)>.text,
      .ui.selection.dropdown :not(.button)>.default.text {
        color: $darkText;
        font-weight: normal;
        opacity: 1;
        font-family: $baseFont;
      }

      .ui.button.btnCustom {
        font-weight: bold;
        font-size: 16px;
        opacity: 1;
      }

      .dataPick_fld {
        margin-right: 15px;
        border-radius: 5px;
        border: 1px solid $borderColor2;
        background-color: $white;
        width: 46px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          border: 1px solid #f4cdd8;
        }

        &:active {
          border: 1px solid $borderColor2;
          background-color: $borderColor2;
        }

        .ant-btn {
          border: none;
          transition: border-color 0ms;
        }

        .ant-btn:active {
          border-color: none;
          border: none;
          color: none;
          box-shadow: none;
          background: none;
          background-color: none;
        }

        .ant-btn:focus {
          border-color: none;
          border: none;
          color: none;
          box-shadow: none;
          background: none;
          background-color: none;
        }

        .ant-btn:hover {
          border-color: none;
          border: none;
          color: none;
          box-shadow: none;
          background: none;
          background-color: none;
        }

        .ant-btn::after {
          border-color: none;
          border: none;
          color: none;
          box-shadow: none;
          background: none;
          background-color: none;
        }
      }

      .search_fldWare {
        width: 760px;

        input::placeholder {
          font-weight: normal;
          font-size: 14px;
          color: $grey;
          font-family: $baseFont;
          opacity: 1;
        }
      }

      .ui.input.focus>input,
      .ui.input>input:focus {
        border-color: #f4cdd8 !important;
      }

      .ui.icon.input>input {
        padding-left: 32px;
      }

      .ui.icon.input>i.link.icon {
        color: $blackfade;
        opacity: 0.3;
        left: 0;
      }
    }
  }

  &_table {
    margin-top: 32px;
    opacity: 1;
    font-weight: bold;
    font-size: 16px;
    color: $darkText;
    font-family: $baseFont;
    border-bottom: 1px solid $greylight;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .sorting_page {
    span {
      font-family: $baseFont;
      color: $blackfade;
      font-weight: normal;
      font-size: 14px;
    }
  }

  &_icon {
    padding-left: 5px;
  }

  &_pagination {
    font-family: $baseFont;
    margin-top: 32px;
    font-weight: normal;
    font-size: 14px;
    color: $blackfade;
    opacity: 1;
    display: flex;
    justify-content: space-between;
  }

  .dropdown-edit {
    &.ui.dropdown {
      border: 0;
    }
  }

  .Table_Editing {
    min-height: 350px;

    a {
      color: #23262c;
      display: contents;
      width: 100%;
    }

    .lastLink {
      border-right: none !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &_header {
    padding-left: 17px;
    padding-right: 35px;
  }
}

.dataPick_windowModal {
  .ant-btn-default {
    color: $darkText2 !important;
    font-weight: 500;
    font-size: 16px;
    opacity: 1;
    font-family: $baseFont;

    &:hover {
      border: 1px solid #f4cdd8 !important;
    }

    &:active {
      background-color: $borderColor2;
      border: 1px solid #f4cdd8 !important;
    }
  }

  .ant-btn-primary {
    background-color: $navTextHoverColor;
    font-weight: 600;
    font-size: 16px;

    &:hover {
      background-color: $Dark-600 !important;
    }

    &:active {
      background-color: $darkButton !important;
    }
  }

  .ant-space {
    border-bottom: 1px solid $borderColor2;
    opacity: 1;
    margin-right: 8px;
    margin-bottom: 32px;
    width: 265px;
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker-dropdown {
    margin-left: 50px !important;
    margin-top: 50px !important;
    top: 170px !important;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 0;

    .dataPick_header {
      background-color: $head-bg;
      height: 100px;
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      padding-left: 24px;

      &__iconImg {
        width: 64px;
        height: 64px;
        background-color: #d0e9c3;
        border-radius: 50%;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__content {
        font-weight: 500;
        color: $darkText2;
        font-size: 20px;
        opacity: 1;
        font-family: $baseFont2;
        margin-bottom: 8px;
      }

      &__paidTitle {
        color: $grey;
        font-weight: normal;
        opacity: 1;
        font-size: 14px;
        font-family: $baseFont;
      }

      &__close {
        width: 32px;
        height: 32px;
        margin-left: 240px;
        border-radius: 50%;
        background-color: $white;
        border: 1px solid $borderlight;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: $Light-100;
        }

        &:active {
          background-color: $borderColor2;
        }
      }
    }

    .greenDataPicker {
      display: flex;
      fill: $green;
    }

    .dataPick_calendar {
      // padding: 32px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &_from {
        display: flex;
        flex-direction: column;
      }

      &_to {
        display: flex;
        flex-direction: column;
      }

      &_line {
        display: flex;
        height: 1px;
        width: 20px;
        border-top: 2px solid $grey2;
        opacity: 1;
        margin-right: 18px;
        margin-top: 25px;
      }

      &_text {
        font-weight: bold;
        font-size: 12px;
        font-family: $baseFont;
        color: $blackfade;
        opacity: 1;
        display: flex;
        margin-top: 32px;
      }
    }
  }

  .ant-modal-footer {
    border-top: 1px solid $borderColor2;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 312px;
    padding-bottom: 32px;
    padding-top: 14px;

    .ant-btn-default {
      width: 100px;
      height: 40px;
      color: $black;
      font-size: 16px;
      font-weight: 500;
      font-family: $baseFont;
      border-radius: 5px;
      border: 1px solid $borderColor2;

      &:hover {
        border: 1px solid $borderColor2;
      }

      &:active {
        background-color: $borderColor2;
      }
    }

    .ant-btn-primary {
      width: 137px;
      height: 40px;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      font-family: $baseFont;
      border-radius: 5px;
      border: 1px solid $borderColor2;

      &:hover {
        background-color: $borderColor2;
      }

      &:active {
        background-color: $borderColor2;
      }
    }
  }
}

.btnArrow {
  background-color: $greyText;
}