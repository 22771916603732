@import "../../../../_var.scss";
.tabsOver {
  &_wrapp {
    padding-bottom: 32px;
    border-bottom: 1px solid $borderColor2;
    margin-bottom: 32px;
  }
  &_wrappLast {
    padding-bottom: 32px;
  }
  &_title {
    font-weight: bold;
    font-size: 14px;
    color: $color-67;
    opacity: 1;
    font-family: $baseFont;
    margin-bottom: 8px;
  }
  &_details {
    opacity: 1;
    font-weight: normal;
    font-size: 16px;
    color: $grey;
    font-family: $baseFont;
    &_link {
      text-decoration: underline;
      text-decoration-color: $grey;
      opacity: 1;
      font-weight: normal;
      font-size: 16px;
      color: $grey;
    }
  }
}
