@import "../../../../../_var.scss";

.actualVersion {
  width: 100%;

  ul {
    li {
      padding: 5px 15px !important;
      border: none !important;

      strong {
        font-size: 12px;
      }

      .listing {
        // display: flex;
        // align-items: center;
        padding-top: 5px;
        // justify-content: space-between;

        p {
          width: 100%;
          margin: 0;
          padding: 0;
          font-weight: 300;
          color: $darkText2;
          font-size: 14px;
        }

        svg {
          // width: 100%;
        }
      }

      .Img_fixed {
        display: flex;
        flex-direction: column;

        img {
          height: 100px;
          width: 100%;
          margin: 10px 0;
        }
      }

      .Commercial {
        width: 100%;
        padding-top: 5px;

        p {
          width: 100%;
          margin: 0;
          padding: 0;
          font-weight: 300;
          color: $darkText2;
          font-size: 14px;
        }
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
