@import "../../../../_var.scss";

.CreatWineft {
  >.left {
    flex: 0 0 200px;
    max-width: calc(100% - 200px);
    border-right: 1px solid #e7e7e7;
    position: relative;
  }

  >.right {
    flex: 1 0;
    padding-left: 60px;
    max-width: calc(100% - 200px);
  }
}

.nftwine {
  &::before {
    content: "";
    height: 64px;
    width: 64px;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: $white;
    background-image: url(../../../../Assets/image/image_Icon.svg) !important;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    border: 1px solid $borderlight;
    z-index: 1;
  }
}

.httpInput {
  margin-top: 32px;

  .ant-input-group-wrapper {
    .ant-input-group {
      height: 40px;
      border-bottom: 1px solid $borderColor2;

      .ant-input-group-addon {
        border: none;
        padding: 0;
        background: transparent;
      }

      .ant-input {
        border: none;
        background: transparent;
      }
    }
  }
}

.bottlefirst {
  margin-bottom: 32px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    color: $bottText;
    margin: 0;
  }
}

.boxCheck {
  margin-bottom: 32px;

  .checkbox--style {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;

    label {
      display: flex;
      align-items: center;
      height: 24px;
      padding-left: 40px;
    }
  }

  p {
    padding-left: 40px;
    color: $color-67;
    font-size: 10px;
  }
}

.RadioBtn {
  margin: 0 auto 32px;

  .radiolabel {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .btnRadio {
    margin-right: 36px;

    label {
      font-size: 16px;
      color: $blackfade !important;
    }
  }

  .RadioInfo {
    display: flex;
    align-items: center;

    .InfoToggle {
      width: 46px;
      height: 40px;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      left: 80px;
    }
  }
}

.RadioInfoVat {
  .radioBtn_style.ui.radio.checkbox label {
    text-transform: unset;
  }
}

.checkboxBlock {
  border-bottom: 1px solid $borderColor2;
  margin-bottom: 50px;
  input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.priceFiled {
  padding-left: 15px;

  @media (max-width: 767px) {
    padding: 0;
  }

  p {
    font-size: 12px;
    margin-bottom: 25px;
    color: $grey;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 700;

    @media (max-width: 1565px) {
      font-size: 12px;
      font-weight: 400;
    }

    @media (max-width: 767px) {
      margin-bottom: 32px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.perameter_Inputs {
  max-width: 450px;
  width: 100%;
  margin-bottom: 32px;
}

.imageUpload {
  p {
    margin-top: 8px;
  }
}

.Qrscan_Code {
  .nfttoggle {
    width: 50px;
    height: 30px;
  }
}

.AddPhotos {
  margin-bottom: 20px;

  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 160px;
    height: 34px;
    cursor: pointer;
  }
}

.InputPresent {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 450px;
  margin-bottom: 32px;

  .InfoInput {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .Sublabel {
    margin-bottom: 12px;
    color: #130207;
    font-weight: 700;
    font-size: 12px;
  }
}

.NftCreate {
  border: none !important;
  margin: 0 !important;
}

.BtnListing {
  .borderBtn.addNew_Btn {
    cursor: pointer;
    min-width: 100px;
    height: 50px !important;
    background: $white;
    border: 1px solid $borderColor2;
    border-radius: 5px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: $darkText2;
    text-transform: capitalize;
    padding: 8px 16px;
    transition: all 0.25s;
    margin-right: 10px;

    &:hover {
      border-color: $darkButton;
    }

    img {
      margin-right: 16px;
    }
  }

  .saveBtn2 {
    min-width: 100px;
    height: 50px !important;
  }
}

.SendBtn {
  min-width: 160px;
  height: 50px !important;
  margin: 0;
}

.BtnPreview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  button {
    background: #ffffff 0% 0% no-repeat padding-box;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 149px;
    height: 32px;
    font-size: 12px;
    font-weight: 500;
    color: #21040c;
  }
}

.m-0 {
  margin: 0 !important;
}

.DropCalender {
  width: 100%;
  text-align: left;
  height: 40px;
  border-bottom: 1px solid #e7e7e7 !important;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../../Assets/image/down_arrow.svg");
    top: 20px;
    right: 12px;
    width: 10px;
    height: 10px;
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}

.Listing {
  h6 {
    font-size: 14px;
    font-family: $baseFont;
    font-weight: 700;
    color: #ffffff;
    margin: 16px 0;
  }

  .popupList {
    padding-left: 15px;
    margin: 0;

    li {
      list-style: disc;
      display: list-item;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      font-family: $baseFont;
    }
  }
}

.createFinalPrice {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  &__title {
    color: $blackfade;
    opacity: 1;
    font-weight: bold;
    font-size: 12px;
    font-family: $baseFont;
    display: flex;
    margin-bottom: 16px;
  }

  &__text {
    display: flex;
    color: $blackfade;
    opacity: 1;
    font-weight: normal;
    font-size: 24px;

  }
}