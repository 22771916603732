@import "../../../../_var.scss";

.marketplace-filters {
  .search_fld {
    width: 100%;
    max-width: 285px;
  }

  .ui.input.focus > input,
  .ui.input > input:focus {
    border-color: #f4cdd8 !important;
  }
  .ui.icon.input > input {
    padding-left: 32px;
  }
  .ui.icon.input > i.link.icon {
    color: $blackfade;
    opacity: 0.3;
    left: 0;
  }
}
