@import "../../../_var.scss";

.DraftNft,
.ListedNft,
.LiveNft,
.MarketPlaceNft {
  .BottleView {
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #dce5dd 0% 0% no-repeat padding-box;
    border-radius: 25px;
    padding: 19px 0 37px;

    .BottleImg {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: 160px;
      height: 529px;
    }
  }

  .Containt {
    p {
      font-size: 16px;
      font-weight: 400;
      color: #6f6f6f;
      margin: 10px auto 24px !important;
      word-break: break-all;
    }

    .listine {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .VauleSelect {
        width: 100%;

        label {
          color: #787567;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        h6 {
          font-size: 16px;
          font-weight: 400;
          color: #5a47ff;
        }
      }
    }

    .BtnList {
      padding: 0;
      margin-top: 20px;

      li {
        display: inline-block;
        padding-right: 20px;

        button {
          cursor: pointer;
          border: 1px solid #e7e7e7;
          border-radius: 5px;
          background: transparent;
          height: 32px;
          color: #21040c;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .KeyValue {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        width: 50%;
        flex-wrap: wrap;

        label {
          color: #787567;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 6px;
        }

        h6 {
          word-wrap: break-word;
          width: 150px;
          font-size: 16px;
          font-weight: 400;
          color: #6f6f6f;
          margin: 0;
        }
      }
    }
  }

  .ChateauComponent {
    .backBtn {
      background: transparent;
      border: none;
      margin-bottom: 20px;

      svg {
        path {
          fill: #21040c;
        }
      }

      a {
        color: #6f6f6f;
        font-size: 12px;
        font-weight: 600;
      }
    }

    .Wraaping {
      h4 {
        color: #5a47ff;
        font-size: 16px;
        font-weight: 500;
      }

      h1 {
        font-size: 24px;
        color: #21040c;
        font-weight: 400;
        font-family: $baseFont2;
        line-height: 32px;
      }

      .UserSelectedIcon {
        width: 65px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        border-radius: 20px;
        font-size: 10px;
        font-weight: 500;
      }

      .draftIcon {
        background: #beb8ba 0% 0% no-repeat padding-box;
        color: #fff;
      }

      .ListedIcon {
        background: #6dbb45 0% 0% no-repeat padding-box;
        color: #fff;
      }

      .LiveIcon {
        background: #f97245 0% 0% no-repeat padding-box;
        color: #fff;
      }

      .MarketIcon {
        min-width: 100px;
        background: #5a47ff 0% 0% no-repeat padding-box;
        color: #fff;
      }

      .PricingList {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .Pricing {
          label {
            color: #787567;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
          }

          h2 {
            color: #21040c;
            font-size: 32px;
            font-weight: 700;
            font-family: $baseFont2;
            line-height: 26px;
            margin-top: 10px;

            .AmountCountry {
              color: #21040c;
              font-size: 20px;
              font-weight: 700;
              font-family: $baseFont2;
              line-height: 26px;
              margin: 0;
            }

            span {
              color: #6f6f6f;
              font-size: 14px;
              font-weight: 400;
              font-family: $baseFont;
              margin-left: 15px;
            }
          }
        }
      }
    }

    .LineSpacing {
      margin: 24px auto;
      background: #e7e7e7;
      height: 2px;
      border: none;
    }
  }

  .ScreenforMb {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .ScreenTbpluse {
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .ItemActive {
    .autowidth {
      min-width: 140px !important;
    }
  }

  .spaingTable {
    min-width: 80px !important;
  }

  .IconSpace {
    svg {
      margin-right: 10px;
    }
  }
}

.TimeEnd {
  label {
    color: #787567;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;

    span {
      color: #932040;
      font-family: "Lora", serif;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .TimerSet {
    margin-top: 15px;
    display: flex;
    align-items: center;

    .Daystime {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .Numberstart {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 110px;
        margin-bottom: 10px;

        p {
          font-size: 32px;
          font-family: $baseFont2;
          font-weight: 700;
          margin: 0;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #ededed;
          border-radius: 10px;
          min-width: 50px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      h6 {
        font-size: 16px;
        font-weight: 500;
        color: #787567;
        font-family: $baseFont;
        line-height: 24px;
      }
    }
  }
}