@import "../../../_var.scss";

.range-slider-outer {
  margin-bottom: 32px;

  .inner {
    display: flex;
    align-items: center;

    .left {
      flex: 0 0 200px;
      max-width: 200px;

      &.auto {
        flex: auto;
        max-width: 100%;
      }

      // padding-right: 5px;
      .ant-input-number {
        border: 0;
        width: 100%;

        &.ant-input-number-focused {
          box-shadow: none;
          border: 0;
        }

        &:focus,
        &:focus-visible {
          border-radius: 0;
          box-shadow: none;
          border: 0;
        }

        .ant-input-number-out-of-range input {
          color: $darkText2;
        }
      }

      input.ant-input-number-input {
        width: 100%;
        border-radius: 0;
        border: 0;
        padding: 17px;
        padding-left: 0;
        border-bottom: 1px solid $borderColor2;
        height: 53px;

        &:focus,
        &:focus-visible {
          border-radius: 0;
          box-shadow: unset;
          border-bottom: 1px solid $navTextHoverColor;
        }
      }

      .ant-input-number-handler-wrap {
        display: none;
      }

      @media (max-width: 767px) {
        flex: 0 0 100px;
        max-width: 100px;
      }
    }

    .right {
      flex: 1 0;
      padding: 0 3px;

      .ant-slider {
        .ant-slider-rail {
          height: 8px;
          border-radius: 10px;

          background-color: $rangebg;
        }

        .ant-slider-track {
          height: 8px;
          border-radius: 10px;
          background-color: $navTextHoverColor;
        }

        .ant-slider-step {
          height: 8px;
        }

        .ant-slider-handle {
          height: 26px;
          width: 26px;
          border: 1px solid $navTextHoverColor;
          margin-top: -9px;

          &:before {
            content: "";
            height: 14px;
            width: 14px;
            border-radius: 50%;
            background-color: $navTextHoverColor;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
          }

          // &:after {}

          &:focus {
            box-shadow: 0 0 0 5px rgba(147, 32, 64, 0.12);
          }
        }
      }
    }
  }
}

.range-slider__text {
  margin-top: 14px;
}