@import "../../../_var.scss";

.dropdown-edit {
  &.ui.dropdown {
    background: $white;
    border: 1px solid $borderColor2;
    border-radius: 5px;
    padding: 5px 12px;

    .icon {
      width: auto;
      height: auto;
      margin: 0;
      font-size: 12px;
    }

    .menu {
      top: 125%;
      background: $mainBg;
      min-width: 230px;
      border: 0;
      border-radius: 0;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .item {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        padding: 0 !important;

        svg {
          margin-right: 7px;
        }

        a {
          padding: 10px 16px;
          color: $blackfade;
          display: block;
        }

        &:hover {
          background: $drop-bg;
          color: $blackfade;
        }
      }

      .iconCrose {
        margin-right: 10px;
        border: 1px solid #000;
        border-radius: 50%;
        padding: 3px;
        width: 18px;
      }
    }
  }
}
