.btnWrapper {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
    gap: 20px;
}

.wareHouse {
    position: relative;
}

.commonSubHeading {
    margin-bottom: 30px;
}