@import "../../../_var.scss";

.CustomAccordion {
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 20px;
  padding: 20px;
  margin: 24px 0;

  .accordion {
    .title {
      margin: 0;
      position: relative;

      .TitlesList {
        font-size: 20px;
        color: #21040c;
        font-weight: 600;
        font-family: $baseFont2;

        img {
          margin-right: 10px;
        }
      }

      &::before {
        position: absolute;
        content: "";
        background-image: url(../../../Assets/image/svgimages/addPlusGrey.svg);
        width: 30px;
        height: 30px;
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center;
        right: 0;
        top: 8px;
        border: 1px solid #f5f5f5;
        border-radius: 50%;
      }
    }

    .active.title {
      position: relative;

      .TitlesList {
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 20px;
      }

      &::before {
        position: absolute;
        content: "";
        background-image: url(../../../Assets/image/svgimages/subtractionIcon.svg);
        width: 30px;
        height: 30px;
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center;
        right: 0;
        top: 8px;
        border: 1px solid #f5f5f5;
        border-radius: 50%;
      }
    }
  }
}
