@import "../../../_var.scss";

.radioBtn_style {
  &.ui.radio.checkbox {
    label {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 400;
      color: $grey;
      padding-left: 22px;

      &::before {
        border: 1px solid #130207;
      }
    }

    input[type="radio"] {
      width: 15px;
      height: 15px;
    }

    &.checked {
      label {
        &::before {
          border: 1px solid $defult-500;
        }
      }

      .box:after,
      label:after {
        border: 1px solid $defult-500;
        background-color: $defult-500;
      }
    }
  }
}