@import "../../../../../Assets/style/_var.scss";

.blueWineftCard {
  &__img {
    display: flex;
    z-index: 50;
    left: 105px;
    top: -50px;
    position: absolute;

    img {
      height: 380px;
      width: 142px;
    }
  }

  &__cardsBackground {
    border-radius: 25px 25px 0 0;
    position: relative;
    width: 350px;
    height: 330px;

    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 25px 25px 0 0;
      background-image: url("../../../../../Assets/image/RedBigGround.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0 75%;
    }
  }

  &__info {
    z-index: 100;
    position: absolute;
    width: 350px;
    height: 220px;
    margin-top: -20px;
    padding: 24px 16px;
    border: 1px solid $borderClr;
    border-radius: 20px;
    background-color: $white;

    &_title {
      font-weight: 600;
      font-size: 16px;
      font-family: $baseFont;
      margin-bottom: 16px;
    }

    &_country {
      display: block;
      margin-top: -8px;
      font-weight: normal;
      font-size: 14px;
      color: $darkGray;
      font-family: $secFont;
    }

    &_winmaker {
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      &_av_default {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 20px;
        background-color: $Dark-600;
        color: #fff;
      }
    }

    &_block {
      margin-top: 8px;
    }

    &_price {
      font-weight: bold;
      font-size: 10px;
      color: $lightText;
      font-family: $secFont;
      padding-bottom: 5px;
    }

    &_value {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 14px;
      font-family: $secFont;
      color: $mainBlack;

      span {
        margin-left: 5px;
      }

      .dollIconCard {
        display: flex;
        margin-left: -5px;
      }
    }

    &_sellerBox {
      margin-top: 20px;
    }

    &_sellerId {
      margin-left: 8px;
    }

    &_seller {
      font-weight: bold;
      font-size: 10px;
      color: $lightText;
      font-family: $secFont;
    }

    &_idNumber {
      font-weight: normal;
      font-size: 12px;
      color: $darkGray;
    }
  }
}