@import "../../../_var.scss";

.drop_label {
  display: block;
  font-size: 13px;
  font-weight: 700;
  color: $darkText2;
}

.drop_style {
  display: flex;
  flex-direction: column;
}

.cus_drop {
  display: flex;
  flex-direction: column;
}

.cus_drop select {
  border-radius: 0;
  border: none;
  padding: 17px 17px 17px 0;
  border-bottom: 1px solid $borderColor2;
  font-size: 15px;
  font-weight: 400;
  color: $darkText;
  text-transform: capitalize;
  background-color: #fff;
  width: 100%;
  cursor: pointer;

  &:focus-visible {
    border: none !important;
    border-bottom: 1px solid $borderColor2 !important;
    outline: none;
    color: $darkText;
  }

  &.empty {
    color: $greylight;
  }
}

.fieldError {
  color: red;
  font-size: 13px;
  margin-right: auto;
  margin-left: 0;
}