@import "../../../_var.scss";

.inputField {

  &__label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    color: #130207;
  }

  &__input {
    width: 100%;

    &.ui.input {

      textarea,
      input {
        border-radius: 0;
        border: 0;
        padding: 17px 17px 17px 0;
        border-bottom: 1px solid $borderColor2;
        color: $darkText;
        font-size: 14px;
        font-family: $baseFont;

        &:focus {
          border-radius: 0;
          box-shadow: unset;
          border-bottom: 1px solid $mainText;
        }

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $greylight;
        }
      }

      textarea {
        resize: none;
        height: 200px;
      }
    }
  }

  &__LimitMsg {
    font-size: 12px;
    margin-top: 16px;
    text-align: right;
    color: $navTextHoverColor;

    &--grey {
      color: $grey2;
    }
  }

  &__wrap {
    margin-bottom: 32px;

    textarea.inputField__input {
      border-radius: 0;
      border: 0;
      padding: 17px;
      padding-left: 0;
      border-bottom: 1px solid $borderColor2;
      resize: none;
      min-height: 210px;

      &:focus,
      &:focus-visible {
        border-radius: 0;
        box-shadow: unset;
        border-bottom: 1px solid $mainText;
        outline: none;
      }

      &::placeholder {
        font-size: 15px;
        font-weight: 400;
        color: $greylight;
      }

      &:disabled {
        opacity: 0.45;
      }
    }

    p {
      color: red;
      font-size: 13px;
      margin: auto;
      margin-top: 5px;
    }

    &.disabled-style {
      .ui.disabled {
        opacity: 1;
      }
    }
  }
}

.File_main {
  .image_upload {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .text_input {
      flex: 0 0 69%;
      height: 40px;
      border: none;
      border-bottom: 1px solid $borderColor2;
      padding: 0;
      color: $darkText2;

      @media (max-width: 767px) {
        flex: 0 0 100%;
      }

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: $grey;
      }
    }

    .file_up {
      display: flex;
      position: relative;
      flex: 0 0 30%;
      margin-left: 10px;

      @media (max-width: 767px) {
        flex: 0 0 100%;
        margin-top: 20px;
        margin-left: 0;
      }

      button {
        position: absolute;
        // z-index: 999;
        width: 100%;
        height: 40px;
        border: 1px solid $borderColor2;
        background: transparent;
        border-radius: 5px;
        color: $darkText2;
        font-size: 16px;
        font-weight: 600;
        z-index: 1;
      }

      .ant-input {
        position: absolute;
        // z-index: 99999;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        height: 100%;
        width: 100%;
        top: 0px;
        min-height: 40px;
      }
    }
  }
}

.Quantity_input {
  margin-bottom: 32px;

  .Numberquantity {
    display: flex;

    .inputadd {
      flex: 0 0 45%;
      border: 0;
      border-bottom: 1px solid $borderColor2;
      background: transparent;

      &:focus {
        box-shadow: none;
      }
    }

    .subtracbtn {
      max-width: 40px;
      flex: 0 0 5%;
      background: $whiteText;
      border: 1px solid $borderColor2;
      border-radius: 5px;
      margin: 0 5px;
      cursor: pointer;
      font-size: 30px;
      font-weight: 700;

      img {
        width: 18px;
      }
    }

    .additionbtn {
      max-width: 40px;
      flex: 0 0 5%;
      background: $whiteText;
      border: 1px solid $borderColor2;
      border-radius: 5px;
      margin: 0 0;
      cursor: pointer;
      font-size: 30px;
      font-weight: 700;
    }

    .removebtn {
      flex: 0 0 30%;
      background: transparent;
      border: none;
      border-radius: 5px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.calender {
  .Calender_input {
    border: 0;
    border-bottom: 1px solid $borderColor2;
    padding-left: 0;
    height: 50px;
    cursor: pointer;

    &:focus,
    &:hover {
      box-shadow: none;
      border-bottom: 1px solid $borderColor2;
    }
  }
}

sup {
  top: -0.2em;
  font-size: 12px;
  color: #932040;
  left: 0.2em;
}