.steps-bottle {

  .antd-custom-step--finished {
    .ant-steps-item-icon {
      svg {
        path {
          fill: #932040
        }
      }
    }
  }

}
