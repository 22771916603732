// .breadcrumb-not-active {
//   color: #cccccc;
// }
// .breadcrumb-active {
//   color: #23262c;
// }
.breadcrumb-arrow {
  margin-left: 10px;
  margin-right: 10px;
}

.BreadCrumbList {
  display: flex;
  align-items: center;
  width: 100%;

  .breadcrumb-not-active {
    color: #23262c8e !important;
    font-size: 16px;
  }

  .breadcrumb-active {
    color: #23262c !important;
    font-size: 16px;
  }

  .iconSpace {
    margin: 0 5px;

    svg {
      height: 10px;
    }
  }

  @media (max-width: 450px) {
    flex-wrap: wrap;

    .breadcrumb-not-active {
      font-size: 14px;
    }

    .breadcrumb-active {
      font-size: 14px;
    }
  }
}
