@import "../../../../../_var.scss";

.labelDropdownMenu {
  background: $white;
  padding: 0;
  border-radius: 0;
  z-index: 11;
  will-change: transform,opacity;

  a {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    padding: 10px 16px;
    color: #130207;
    display: block;

    &:hover {
      background: #def1ff;
    }

    svg {
      margin-right: 7px;
    }
  }
}
