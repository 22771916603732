@import "../../../_var.scss";

.checkbox--style {
  &.ui.checkbox {
    min-width: 24px;
    min-height: 24px;
    vertical-align: bottom;
    @media (max-width: 767px) {
      min-width: 18px;
      min-height: 18px;
    }
    input[type="checkbox"] {
      width: 24px;
      height: 24px;
      @media (max-width: 767px) {
        width: 18px;
        height: 18px;
      }
    }
    label {
      &::before {
        width: 24px;
        height: 24px;
        border-radius: 3px;
        border: 1px solid $grey2 !important;
        @media (max-width: 767px) {
          width: 18px;
          height: 18px;
        }
      }
    }

    &.checked {
      label {
        &::before {
          border-color: $defult-500 !important;
        }

        &::after {
          content: "";
          width: 24px;
          height: 24px;
          background-image: url(../../../Assets/image/svgimages/w1.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 20px;
          @media (max-width: 767px) {
            width: 18px;
            height: 18px;
            background-size: 12px;
          }
        }
      }
    }
  }
}
