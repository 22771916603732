@import "../../../../_var.scss";

.actualVersion {
  width: 100%;

  .Byadmin {
    width: 100%;

    p {
      padding-left: 0 !important;
      font-size: 12px !important;
      color: $greyLight;
    }

    h6 {
      font-size: 18px;
      color: $darkText;
      position: relative;
      border-bottom: 1px solid $borderColor3;
      padding-left: 15px;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: $defult-500;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }

  .ByUser {
    width: 100%;

    p {
      padding-left: 0 !important;
      font-size: 12px !important;
      color: $greyLight;
    }

    h6 {
      font-size: 18px;
      color: $darkText;
      position: relative;
      border-bottom: 1px solid $borderColor3;
      padding-left: 15px;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: $greydark;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }

  h3 {
    font-size: 16px;
  }

  ul {
    li {
      padding: 5px 15px !important;
      border: none !important;

      .InformShow {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 23px;
        overflow: hidden;
        align-items: flex-start;
        // border-bottom: 1px solid $borderColor3;

        button {
          cursor: pointer;
          background: transparent;
          border: none;
          height: 20px;
          font-size: 12px;
          color: $defult-500;
          font-weight: 600;
        }
      }

      strong {
        font-size: 12px;
      }

      .listing {
        // display: flex;
        // align-items: center;
        padding-top: 5px;
        // justify-content: space-between;
        width: 100%;

        p {
          width: 100%;
          margin: 0;
          padding: 0;
          font-weight: 300;
          color: $darkText2;
          font-size: 14px;
        }

        // svg {
        //   width: 100%;
        // }
      }

      .Commercial {
        width: 100%;
        padding-top: 5px;

        p {
          width: 100%;
          margin: 0;
          padding: 0;
          font-weight: 300;
          color: $darkText2;
          font-size: 14px;
        }
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
