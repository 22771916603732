@import "../../../../_var.scss";

.create-public-page {
  white-space: pre-line;

  .logs-controller {
    padding-right: 30px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      h3,
      .logs-toggler {
        margin-bottom: 15px;
      }

      .logs-toggler {
        height: 32px;
        width: 32px;
        display: block;
        border-radius: 50%;
        border: 1px solid $borderColor2;
        background-image: url(../../../../Assets/image/svgimages/eye-close.svg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
        transition: 0.3s all ease-in-out;

        &.active {
          background-image: url(../../../../Assets/image/svgimages/eye-open.svg);
          background-color: $borderColor2;
        }
      }
    }

    h4 {
      font-size: 16px;
      color: $grey40;
    }

    ul {
      padding: 0;

      li {
        flex-direction: column;
        padding: 16px 0;
        margin: 0;

        >span {
          color: $greyLight;
          font-size: 12px;
          display: block;
        }

        p {
          position: relative;
          padding-left: 15px;
          color: $darkText;
          font-size: 16px;
          font-weight: 500;
          padding-top: 5px;

          &:before {
            content: "";
            height: 8px;
            width: 8px;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: 0;
            top: 13px;
          }

          &.byadmin {
            &::before {
              background-color: $navTextHoverColor;
            }
          }

          &.bymaker {
            &::before {
              background-color: $greydark;
            }
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid $borderColor2;
        }
      }
    }
  }

  .right,
  .left {
    .top-controll {
      max-width: 540px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 991px) {
        max-width: 100%;
      }

      .publicprev {
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        // padding-left: 40px;
        // background-image: url(../../../../Assets/image/svgimages/prev-icon.svg);
        // background-position: 15px center;
        // background-size: auto;
        // background-repeat: no-repeat;
        border-radius: 5px;
        transition: 0.3s all ease-in-out;
        color: $darkText2;
        border: 1px solid $borderColor2;

        svg {
          margin-right: 10px;
          vertical-align: middle;
          // margin-left: 5px;
        }

        &:hover {
          border-color: $borderColor;
        }

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      >a {
        margin-bottom: 15px;
      }
    }
  }

  @media (min-width: 992px) {
    >.left {
      border-right: 1px solid transparent;

      .top-controll {
        display: none;
      }

      &.brdr {
        border-right: 1px solid $borderColor2;
      }
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;

    .right {
      padding-left: 0;

      .top-controll {
        display: none;
      }
    }

    >.left,
    >.right {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .left {
      border: 0;
      border-bottom: 1px solid $borderColor2;
      margin-bottom: 25px;

      .logs-controller {
        justify-content: space-between;
        padding-right: 0;

        .bottom {
          .ant-row {
            justify-content: space-between;

            >.ant-col {
              flex: 0 0 45%;
              max-width: 45%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .left {
      .logs-controller {
        .bottom {
          .ant-row {
            >.ant-col {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}