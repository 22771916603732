@import "../../_var.scss";

.ui.dimmer {
  background: $modalBgclr !important;
  border: 1px solid $grey2;

  &.inverted {
    background: #37373736 !important;
    border: 0 !important;
  }
}

.modal__style {
  &.ui.modal {
    max-width: 500px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 7px 15px #e7e7e780;
    border: 1px solid $borderColor2;

    @media (max-width: 767px) {
      max-width: 320px;
    }

    .header {
      padding: 24px;
      background: $head-bg;
      font-size: 20px;
      line-height: 27px;
      font-weight: 600;
      color: $darkText2;
      font-family: $baseFont2;
      display: flex;
      justify-content: space-between;
      border: 0;

      @media (max-width: 767px) {
        padding: 15px !important;
        max-width: 320px;
        font-size: 13px;
      }

      .close-btn {
        width: 26px;
        height: 26px;
        background: $white;
        border-radius: 50%;
        border: 0;
        padding: 0;
        margin-top: -5px;
        cursor: pointer;
        transition: all linear 0.2s;

        &:hover {
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        @media (max-width: 767px) {
          padding: 3px;
        }
      }
    }

    .content {
      padding: 24px !important;

      @media (max-width: 767px) {
        padding: 15px !important;
      }
    }

    .actions {
      background: $white;
      text-align: right;
      border-top: 1px solid $borderColor2;
      padding: 24px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        padding: 15px !important;
      }

      .btnCustom {
        &.ui.button {
          font-size: 16px;
          font-weight: 500;
          margin: 0;

          @media (max-width: 767px) {
            font-size: 11px;
            height: 32px;
          }
        }
      }

      .borderBtn {
        font-size: 16px;
        font-weight: 500;

        @media (max-width: 767px) {
          font-size: 11px;
        }
      }
    }
  }
}

.content {
  .listing {
    p {
      word-break: break-all;
    }
  }
}
