@import "../../../../_var.scss";

.boxInfo {
  width: 470px;

  &__title {
    font-weight: 500;
    font-size: 16px;
    opacity: 1;
    color: $grey;
    font-family: $baseFont;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &__country {
    color: $grey;
    opacity: 1;
    font-weight: normal;
    font-size: 12px;
    font-family: $baseFont;
    margin-bottom: 24px;
  }

  &__row {
    padding-bottom: 24px;
    border-bottom: 1px solid $borderColor2;
  }

  &__emptyCell {
    width: 100%;
    min-height: 24px;
  }

  &__pTitle {
    color: $color-67;
    font-weight: bold;
    font-size: 14px;
    font-family: $baseFont;
    opacity: 1;
    margin-bottom: 8px;
  }

  &__pDetails {
    color: $grey;
    opacity: 1;
    font-weight: normal;
    font-size: 16px;
    font-family: $baseFont;
  }

  &__rowName {
    font-size: 14px;
    font-weight: bold;
    opacity: 1;
    color: $darkText2;
    font-family: $baseFont2;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__pDate {
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
    color: $color-67;
    font-family: $baseFont;
    margin-bottom: 8px;
  }

  &__pPayment {
    opacity: 0.7;
    color: $darkText;
    font-size: 16px;
    font-weight: normal;
    font-family: $baseFont;
  }

  &__pPaid {
    font-weight: normal;
    font-size: 16px;
    opacity: 0.7;
    color: $darkText;
    font-family: $baseFont;
  }

  &__termRow {
    margin-top: 24px;
  }
}