@import "../../_var.scss";

.leftBar {
  width: $left-menu-size;
  z-index: 10;
  background: $mainBg;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    position: fixed;
    left: 0;
    z-index: 9999;

    &.isShow {
      left: 0;
    }
  }

  h2.navHeading {
    display: none;
    padding: 16px 0 0 48px;
    font-size: 12px;
    text-transform: uppercase;
    color: $darkText;
    box-sizing: border-box;

    @media (max-width: 1366px) {
      padding-left: 19px;
    }
  }

  .sidebarNav {
    margin-bottom: 5px;

    ul {
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        display: block;
        margin-top: 0;
        margin-bottom: 0;

        a {
          font-size: 13px;
          color: $white;
          font-weight: 400;
          font-style: normal;
          margin-bottom: 0;
          text-decoration: none;
          display: flex;
          padding: 10px 10px 10px 15px;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          transition: all 0.1s ease-in-out;
          position: relative;
          height: 60px;
          white-space: nowrap;

          &:before {
            content: "";
            height: 100%;
            width: 5px;
            background: $defult-500;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 0 4px 4px 0;
            opacity: 0;
          }

          &:hover,
          &.active {
            background-color: $sidebaractive !important;

            &:before {
              opacity: 1;
            }
          }

          img,
          svg {
            width: 14px;
            height: auto;
            display: inline-block;
            margin-right: 7px;
          }

          @media (max-width: 991px) {
            padding-left: 20px;
          }

          .counter {
            background-color: $mainText;
            color: $white;
            height: 18px;
            width: 18px;
            display: flex;
            right: 10px;
            transform: translateY(-50%);
            pointer-events: none;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            font-size: 10px;

            @media (max-width: 767px) {
              height: 22px;
              width: 22px;
              right: 10px;
            }
          }
        }
      }
    }

    > h4 {
      font-size: 18px;
      color: #39cbf6;
      font-weight: 400 !important;
      text-align: center;
      font-style: normal;
      margin-bottom: 23px;
      margin-top: 0;
      width: 100%;

      @media (max-width: 991px) {
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }

    > label {
      background: #2a384a;
      width: 100%;
      padding: 15px 0;
      border-radius: 8px;
      color: $darkText;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 40px;
      text-align: center;
      display: block;

      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }

    &.sidebar-act {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  .sn_identity {
    @media (max-width: 991px) {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .total_earn {
      background: #17212f;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      padding: 15px 0 18px 0;

      &.last {
        padding-bottom: 0 !important;

        @media (max-width: 767px) {
          width: 100% !important;
        }
      }

      .invest_btn {
        width: 100%;
        font-size: 16px;
        color: $darkText;
        text-transform: uppercase;
        font-weight: 400;
        background-image: $gradient;
        border: 0px;
        margin-top: 20px;
        border-radius: 0 0 5px 5px;
        padding: 15px 0;
        cursor: pointer;

        &:hover {
          background: linear-gradient(to right, #1c8ee3, #41cff0);
        }
      }

      @media (max-width: 991px) {
        width: 18%;
        text-align: center;
      }

      @media (max-width: 767px) {
        width: 48%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      label {
        font-size: 30px;
        line-height: 35px;
        color: $darkText;
        font-weight: 300;
        margin-bottom: 0;

        @media (max-width: 1250px) {
          font-size: 25px;
        }
      }

      span {
        font-size: 16px;
        color: $darkText;

        @media (max-width: 1250px) {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 1366px) {
      margin-bottom: 10px;
    }
  }

  &.smallSidebar {
    .sidebarNav {
      ul {
        li {
          a {
            padding-left: 10px;

            img {
              display: table;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.sn_matTypeTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;

  p {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    margin: 8px 0 !important;
  }
}

.left_logoBlock {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 10px 0 15px;
  background-color: $white;
  min-height: 70px;
  box-shadow: 0 1px 8px #00000029;

  .sideLogo_icon {
    display: none;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 1366px) {
    padding: 0 10px;
  }

  @media (max-width: 980px) {
    margin-bottom: 10px;
  }

  .toggleicon {
    display: none;
  }
}

.sn_LinkBar {
  margin-bottom: 30px;

  &.LinkBar {
    padding-top: 20px;

    @media (max-width: 991px) {
      padding-top: 0;
    }
  }

  label {
    font-size: 18px;
    color: $darkText;
    font-weight: 300;
    display: block;
    margin-bottom: 10px;

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }

  .linkBlock {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    background: #17212f;

    span {
      font-size: 12px;
      color: $darkText;
      font-weight: 400;
      overflow: hidden;
      flex: 1;
      white-space: nowrap;
    }

    a {
      margin-left: 10px;

      img {
        width: 12px;
        margin-top: 4px;
      }
    }
  }
}

@media (max-width: 991px) {
  .mainBlock .leftBar .left_logoBlock {
    text-align: center;
    justify-content: space-evenly;
  }

  .mainBlock .leftBar .sidebarNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .mainBlock .leftBar .sn_identity {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .sn_LinkBar,
  .sn_matType {
    width: 32%;
    margin-bottom: 0px;
  }

  .sn_matTypeTop {
    width: 100%;
    margin-bottom: 30px;
  }

  .sn_LinkBar a.ethScanLink {
    font-size: 21px;
  }

  .sn_matType ul li {
    flex-direction: row;
  }

  .sn_matType h2 {
    width: 72px;
    height: 72px;
    border-radius: 5px;
    font-size: 33px;
    margin-right: 14px;
  }

  .sn_matType ul li strong {
    font-size: 25px;
    line-height: 28px;
  }

  .mainBlock .leftBar .sn_identity ul li {
    flex-direction: row;
  }

  .sn_matType ul li span {
    font-size: 25px;
    line-height: 28px;
    padding-left: 5px;
  }
}

@media (max-width: 767px) {
  .sn_LinkBar {
    width: 100%;
    margin-bottom: 0px;
  }

  .sn_LinkBar.LinkBar {
    margin-bottom: 20px;
  }

  .sn_matType {
    width: 100%;
    margin-bottom: 20px;
  }

  .mainBlock .leftBar .sn_identity {
    margin-bottom: 10px;
  }
}

$color-blue: #000;
$color-blue-dark: #0d2035;
$submenu-width: 180px;

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  line-height: 1.5;
}

.menu-container {
  text-align: center;
}

nav {
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.nav {
  display: inline-block;
  margin: 0.5rem auto 0;
  background: $color-blue-dark;
  color: #fff;
  text-align: left;

  a {
    display: block;
    padding: 0 6px;
    line-height: inherit;
    cursor: pointer;
  }
}

.nav__menu {
  line-height: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  width: $submenu-width;
  background-color: $color-blue;
}

.nav__menu-item {
  display: flex;
  position: relative;

  &:hover {
    background-color: $mainText;

    .nav__submenu {
      display: block;
    }
  }
}

.nav__submenu-item {
  &:hover {
    background: $mainText;
  }
}

.dashboard {
  background: $white;
}

.nav__menu-item:hover ul.nav__submenu {
  display: block;
  margin-top: 64px;
}

.smallSidebar {
  .left_logoBlock {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mainBlock {
  .leftBar {
    @media (max-width: 991px) {
      left: -300px;

      &.smallSidebar {
        left: 0 !important;
        max-width: 250px !important;
        box-shadow: 10px 0px 18px rgba(0, 0, 0, 0.1);

        .left_logoBlock {
          .logo_icon {
            display: block !important;
          }
        }

        .sidebarNav {
          ul {
            li {
              a {
                img {
                  margin: 0 7px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
