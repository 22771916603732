@import "../../../_var.scss";

.wrapper {
  display: grid;
  gap: 20px;
  padding: 15px 0 30px;
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
}

.uploadWrapper {
  padding: 15px;
  border: 1px solid #ededed;
}

.btnsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.uploadFileBtnWrapper {
  max-width: 368px;
}

.uploadFileBtn {
  &[class*=ui] {
    &[class*=button] {
      &[class*=btnCustom] {
        height: 50px;
        border-radius: 5px;
        background-color: #fff;
        color: #000;
        border: 1px solid #ededed;

        &:hover {
          border-color: #000;
        }

        svg path {
          fill: #000;
        }
      }
    }
  }
}

.submitBtn {
  &[class*=ui] {
    &[class*=button] {
      &[class*=btnCustom] {
        min-width: 172px;
        height: 50px;
        border-radius: 5px;
      }
    }
  }
}

.resultWrapper {
  margin-top: 20px;
}