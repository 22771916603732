@import "../../../../_var.scss";

.wareContainer {
  margin-left: -30px;
  display: flex;
  margin-top: -35px;
  padding-left: 40px;
  padding-top: 40px;

  &_container__wrapper__sidebar {
    background-color: #fff;
    margin-top: -35px;
    margin-left: -40px;
    padding-left: 40px;
    padding-top: 40px;
    width: 750px;
    height: 1000%;
    min-height: 1075px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $borderColor2;
  }

  &_btn {
    height: 32px;
    width: 120px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border-color: $white;
    font-weight: 600;
    font-size: 12px;
    color: $grey;
    padding-left: 8px;
    margin-bottom: 16px;

    span {
      margin-right: 8px;
    }

    &:hover {
      color: $darkText2;
      font-size: 12px;
      border-color: $white;
    }

    &:active {
      color: $darkText2;
      font-size: 12px;
      border-color: $white;
      background-color: $borderColor2;
    }

    &:focus {
      color: $darkText2;
      font-size: 12px;
      border-color: $white;
      background-color: $borderColor2;
    }
  }

  .ant-btn:focus {
    border-color: none;
    box-shadow: none;
  }

  .ant-btn:hover {
    border-color: none;
    box-shadow: none;
  }

  .ant-btn::after {
    border-color: none;
    box-shadow: none;
  }

  .wareNftLeft {
    display: flex;
  }

  &_container__checkContent {
    padding-top: 55px;
    padding-left: 75px;
  }

  &_container__title {
    color: $darkText2;
    opacity: 1;
    font-weight: normal;
    font-size: 32px;
    font-family: $baseFont2;
    margin-bottom: 50px;
  }

  &_container__box {
    box-shadow: 0px 3px 6px #53565c29;
    display: flex;
    width: 470px;
    background-color: $white;
    padding: 18px 21px 40px 16px;
  }

  &_container__cubic {
    display: flex;
  }

  &_container__cubics {
    width: 20px;
    height: 20px;
    background-color: $mainBg;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transform: rotate(15deg);
    transform: rotate(-135deg);
    margin-top: -7px;
    margin-left: 9px;
  }
}

@media screen and (min-width: 1600px) {
  .wareContainer {
    padding-left: 80px;

    &_container__wrapper__sidebar {
      margin-left: -80px;
      padding-left: 80px;
      width: 910px;
    }
  }
}