@import "../../../../_var.scss";

.access__modal {
  // &--content,
  // &--footer {
  //   padding: 24px;
  //   @media (max-width: 767px) {
  //     padding: 15px;
  //   }
  // }

  &--content {
    margin-bottom: 48px;
    .ant-tabs-nav-wrap {
      justify-content: space-between;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #21040c;
      font-family: $baseFont2;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        font-size: 15px;
        margin-bottom: 15px;
      }
    }

    .inputField__wrap {
      margin-bottom: 0;
      .inputField__label {
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
      }
      .ui.input {
        input {
          padding: 14px 0;
        }
      }
    }

    .add-csv {
      display: flex;
      align-items: flex-end;
      gap: 16px;
      .inputField__wrap {
        width: calc(100% - 138px);
        @media (max-width: 767px) {
          width: calc(100% - 100px);
        }
      }
      .select-file {
        min-width: 122px;
        height: 40px;
        font-size: 16px;
        font-weight: 500;
        @media (max-width: 767px) {
          min-width: 90px;
          font-size: 11px;
          height: 32px;
        }
      }
    }
  }
  // &--footer {
  //   text-align: right;
  //   border-top: 1px solid $borderColor2;
  //   .btnCustom {
  //     &.ui.button {
  //       font-size: 16px;
  //       font-weight: 500;
  //       @media (max-width: 767px) {
  //         font-size: 11px;
  //         height: 32px;
  //       }
  //     }
  //   }
  // }
}
