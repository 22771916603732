.countdownSec {
  padding: 24px 0;
  // border-top: 1px solid #e7e7e7;
  // border-bottom: 1px solid #e7e7e7;
  // margin-bottom: 24px;

  .countTxt {
    font-size: 16px;
    font-weight: 500;
    font-family: "Lora", serif;
    text-transform: uppercase;
    color: #787567;
    margin-bottom: 16px;

    @media (max-width: 991px) {
      font-size: 14px;
    }

    @media (max-width: 767px) {
      font-size: 13px;
    }
  }

  .CounteBoxOuter {
    display: flex;
    gap: 16px;

    @media (max-width: 991px) {
      gap: 10px;
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      gap: 8px;
    }

    .dFlex__Style {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .borderBox {
        //   border: 1px solid #ededed;
        border-radius: 10px;
        width: 110px;
        height: 60px;
        color: #21040c;
        font-size: 32px;
        line-height: 43px;
        font-weight: bold;
        font-family: "Cinzel", serif;
        text-align: center;
        margin-bottom: 8px;
        //   background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          background-color: #ffffff;
          border: 1px solid #ededed;
          border-radius: 10px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 50px;
          height: 60px;
        }

        @media (max-width: 991px) {
          font-size: 26px;
          height: 50px;
          width: 80px;
        }

        @media (max-width: 767px) {
          font-size: 20px;
          height: 40px;
          width: 60px;
        }
      }

      .alternateBoxTxt {
        font-size: 16px;
        font-weight: 500;
        font-family: "Lora", serif;
        color: #787567;

        @media (max-width: 991px) {
          font-size: 14px;
        }

        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
    }

    .dotsBox {
      font-size: 24px;
      font-weight: 500;
      font-family: "Lora", serif;
      color: #787567;
      margin-top: 10px;

      @media (max-width: 991px) {
        font-size: 20px;
      }

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}
