@import "../../../_var.scss";

.gridCardStyle {
  border: 1px solid $borderColor2;
  margin-top: 60px !important;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  box-shadow: 0px 6px 10px #e0d7c6;
  width: 285px;
  height: 435px;
  .ant-card-body {
    padding: 0;
  }

  &__bottleWrap {
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;

    .editBottle {
      width: 95px;
      img {
        height: 250px;
        &:hover {
          transform: scale(1.06);
        }
      }
    }
    .editTime {
      display: flex;
      flex-direction: column;
      width: 70%;
      padding: 16px 16px 16px 0;
    }
    .sizeBottle {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding-left: 5px;
    }
    .sizeTitle {
      font-size: 13px;
      line-height: 13px;
      font-family: $baseFont;
      font-weight: bold;
      color: $color-67;
      margin-bottom: 4px;
      display: flex;
    }
    .timeColor {
      color: $timeStart;
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
      text-align: left;
    }
    .sizeDetails {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      color: $darkText;
      margin-bottom: 12px;
      font-family: $baseFont;
    }
    .countryCode {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;
      img {
        border-radius: 50%;
      }
    }

    img {
      position: relative;
      max-width: 100%;
      height: 250px;

      @media (max-width: 1679px) {
        height: 238px;
        width: auto;
        max-width: 100%;
      }
    }
  }
  &__wineryTitles {
    display: flex;
  }
  &__details {
    position: absolute;
    background: $white;
    margin-top: 10px;
    padding: 0 16px;

    // overflow: hidden;
    width: 100%;

    h2 {
      font-family: $baseFont2;
      color: $blackfade;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 16px;
      text-align: left;
      letter-spacing: 0px;
      opacity: 1;
      word-break: break-word;
      display: flex;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }

    p {
      display: block;
      font-size: 12px;
      color: $grey;
      font-weight: normal;
      font-family: $baseFont;
      font-style: italic;
    }

    &__userDetail {
      padding-left: 0;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      margin-bottom: 0;

      &__img {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        margin-right: 8px;

        img {
          width: 100%;
        }
      }

      li {
        display: flex;
        flex-direction: row;
        width: 50%;

        strong {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          line-height: 13px;

          span {
            font-size: 10px;
            color: $grey40;
            font-weight: 700;
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }

    &__gridDropMessage {
      border-top: 1px solid #666;
      margin-top: 17px;

      p {
        font-size: 17px;
        padding-top: 10px;
      }
    }
  }

}

.discription {
  position: absolute;
  bottom: 39px;
  left: 50%;

  img {
    max-width: 100%;
    width: 120px;
    object-fit: fill;
    height: 139px;
  }
}

.BottleDemo {
  width: 100%;
  height: 100%;
  position: relative;
}
