.CalendarDateTime {
  label {
    display: block;
    font-size: 13px;
    font-weight: 700;
    color: #21040c;
  }

  .input-picker {
    position: relative;

    input.input-picker--input {
      width: 100%;
      padding: 10px 30px 10px 8px !important;
      font-size: 14px;
      color: #21040c;
      border: none;
      border-bottom: 1px solid #e7e7e7;
      border-radius: 0;
      height: 45px;
      text-align: left;
      position: relative;
      z-index: 2;
      background: transparent;

      &:focus {
        background: transparent;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 13px;
      right: 13px;
      background-image: url("../../../Assets/image/svgimages/CalenderIcon.svg");
      width: 18px;
      height: 20px;
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
    }
  }

  .calender-modal {
    width: 100% !important;
    padding: 10px;

    .header {
      background: #932040 !important;
    }

    ul.daysList {
      .daysList-day {
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .is-selected-day {
        background-color: #932040;
        color: white;
        border-radius: 0;
      }

      .is-today {
        box-shadow: inset 0px 0px 0px 2px #932040;
        border-radius: 0;
      }

      .is-disabled.is-prev-month.is-border-right-0,
      .is-border-left-0 {
        border-radius: 0;
      }
    }

    .time {
      .time--title {
        font-size: 16px;
        font-weight: 500;
        font-family: "Lora", serif;
        text-transform: capitalize;
      }
    }
  }
}