@import "../../../../_var.scss";
.pallet {
  padding-top: 5px;
  padding-left: 20px;
  &_btn {
    height: 32px;
    width: 120px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border-color: $white;
    background-color: $white;
    font-weight: 600;
    font-size: 12px;
    color: $grey;
    padding-left: 8px;
    margin-bottom: 24px;
    span {
      margin-right: 8px;
    }
    &:hover {
      color: $darkText2;
      font-size: 12px;
      border-color: $white;
    }
    &:active {
      color: $darkText2;
      font-size: 12px;
      border-color: $white;
      background-color: $borderColor2;
    }
    &:focus {
      color: $darkText2;
      font-size: 12px;
      border-color: $white;
      background-color: $borderColor2;
    }
  }
  &_wineName {
    font-weight: 500;
    font-size: 22px;
    color: $darkText;
    opacity: 1;
    font-family: $baseFont2;
    width: 840px;
    margin-bottom: 16px;
  }
  &_headers {
    color: $greyText;
    font-weight: normal;
    font-size: 14px;
    font-family: $baseFont;
    &_details {
      color: $greyText;
      font-weight: bold;
      font-size: 14px;
      font-family: $baseFont;
    }
  }
  &_row {
    margin-bottom: 32px;
    width: 840px;
  }
  .filltersBottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .left_fill {
      display: flex;
      flex-direction: row;
    }
  }
  .rightfill {
    display: flex;
    flex-direction: row;
    margin-right: 210px;
    span {
      margin-right: 8px;
      color: $greyText;
      opacity: 1;
      font-size: 14px;
      font-weight: normal;
      font-family: $baseFont;
    }
  }
  &__search_fld {
    margin-left: 32px;
  }

  &__sorting {
    margin-left: 32px;
    span {
      margin-right: 8px;
    }
  }
  .dataPick_fld {
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid $borderColor2;
    background-color: $white;
    width: 46px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #f4cdd8;
    }
    &:active {
      border: 1px solid $borderColor2;
      background-color: $borderColor2;
    }
    .ant-btn {
      border: none;
      transition: border-color 0ms;
    }
    .ant-btn:active {
      border-color: none;
      border: none;
      color: none;
      box-shadow: none;
      background: none;
      background-color: none;
    }

    .ant-btn:focus {
      border-color: none;
      border: none;
      color: none;
      box-shadow: none;
      background: none;
      background-color: none;
    }
    .ant-btn:hover {
      border-color: none;
      border: none;
      color: none;
      box-shadow: none;
      background: none;
      background-color: none;
    }

    .ant-btn::after {
      border-color: none;
      border: none;
      color: none;
      box-shadow: none;
      background: none;
      background-color: none;
    }
  }
  .search_fldWare {
    width: 760px;
    input::placeholder {
      font-weight: normal;
      font-size: 14px;
      color: $grey;
      font-family: $baseFont;
      opacity: 1;
    }
  }
  .ui.input.focus > input,
  .ui.input > input:focus {
    border-color: #f4cdd8 !important;
  }
  .ui.icon.input > input {
    padding-left: 32px;
  }
  .ui.icon.input > i.link.icon {
    color: $blackfade;
    opacity: 0.3;
    left: 0;
  }

  .ui.selection.dropdown {
    background-color: $white;
    margin-right: 32px;
  }
  &__table {
    padding-left: 15px;
    padding-right: 20px;

    padding-bottom: 16px;
    border-bottom: 1px solid $greylight;
    color: $darkText;
    opacity: 1;
    font-weight: bold;
    font-size: 16px;
    font-family: $baseFont;
    margin-bottom: 16px;
  }
  &_pagination {
    margin-top: 32px;
    font-weight: normal;
    font-size: 14px;
    color: $blackfade;
    opacity: 1;
    display: flex;
    justify-content: space-between;
  }
  &__sort {
    margin-left: 0;
  }
}
