@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700&family=Cinzel:wght@400;500;600;700&display=swap");
$baseFont: "Lora", arial, serif;
$baseFont2: "Cinzel", serif;
$mainBg: #f4f8fb;
$drop-bg: #def1ff;
$selectedColor: #e0ddff;
$boxBg: #ededed;
$whiteText: #fff;
$greyLight: #6d6d6d;
$grey: #6f6f6f;
$grey2: #707070;
$darkText: #23262c;
$darkText2: #21040c;
$white: #fff;
$navTextHoverColor: #932040;
$darkButton: #541225;
$defult-500: #932040;
$Dark-600: #751a34;
$Light-400: #c92c57;
$greyBorder: #ededed;
$borderColor: #e27997;
$mainText: #932040;
$blackBg: #000;
$black: #000;
$devider: #12020724;
$gradient: linear-gradient(to right, #e27997, #932040);
$gradientHover: linear-gradient(to right, #932040, #e27997);
$Light-100: #f7f7ff;
$borderColor2: #e7e7e7;
$borderColor3: #c9c9c9;
$stepactive: #f3e9ec;
$stepactiveborder: #872a41;
$sidebarcolor: #516f84;
$sidebaractive: #336080;
$lightgrey: #fbfbfb;
$borderlight: #f5f5f5;
$blackfade: #130207;
$greylight: #afafaf;
$grey30: #eeeeee;
$grey40: #7b7d80;
$greydark: #908bb9;
$greylight: #afafaf;
$grey30: #eeeeee;
$color-ba: #beb8ba;
$modal-shadow: #859087;
$color-67: #787567;
$head-bg: #eff3ef;
$diclined: #b83327;
$yellow: #efe736;
$yellow2: #fffdd0;
$pendingtext: #726c14;
$green: #6dbb45;
$orange: #f97245;
$borderColorLight: #f4cdd8;
$rangebg: #eaeaea;
$togglebg: #d74770;
$borderRead: #8c1e3d;
$lightBlue: #4f9ad0;
$bottText: #5a47ff;
$modalBgclr: #2a2a2ae0;
$backGr: #fafcff;
$greyText: #868686;
$fashedBorder: #D0D0D0;

// sizes
$left-menu-size: 185px;
$timeStart: #ADA523;