@import "../../_var.scss";

.logoBlock {
  text-align: center;
  margin: 0px;
  padding: 0;
  width: 250px;

  img {
    max-width: 100%;
  }

  @media (max-width: 1366px) {
    padding: 0 10px;
  }
}

.header_crruntActivityRow {
  display: flex;
  border-top: 1px solid rgba(242, 244, 255, 0.14);
  background: #333;
  padding: 0px 25px;
  margin: 0;

  li {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 65px;
    font-size: 12px;
    min-width: 120px;

    strong {
      color: $black;
    }

    span {
      color: $darkText;
    }
  }
}

.toggleicon {
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;

  img {
    width: 24px;
    height: 22px;
    display: inline-block;
    cursor: pointer;

    &.iconclose {
      display: none;
    }
  }

  &#toggleicon {
    img {
      &.iconbar {
        display: none;
      }

      &.iconclose {
        display: inline-block;
        height: auto;
        transform: scale(0.9);
      }
    }
  }
}

.NotifiNumber {
  position: absolute;
  top: -3px;
  right: -18px;
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: #932040;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  border-radius: 13px;

  p {
    color: #fff;
    font-size: 14px;
    margin: 0;
  }
}

.HeaderSection {
  .headerLogo.desk {
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }
}
